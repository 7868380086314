
import { computed, defineComponent, ref } from 'vue'
import { useStore } from 'vuex'
import ConditionsList from '@/components/ConditionsList.vue'
import ConditionModal from '@/components/ConditionModal.vue'
import NewConditionModal from "@/components/NewConditionModal.vue";

export default defineComponent({
  components: {
    NewConditionModal,
    ConditionsList,
    ConditionModal,
  },
  setup(){
    const store = useStore()
    const editMode = computed(() => store.state.editMode)

    const conditionDescriptionVisible = ref(false)
    const conditionIndex = ref(-1)
    const viewConditionDescription = (index: number) => {
      if (!newConditionVisible.value){
        // close current before opening new
        if (conditionDescriptionVisible.value && conditionIndex.value !== index) {
          conditionDescriptionVisible.value = false
          setTimeout(viewConditionDescription, 100, index)
        } else {
          conditionDescriptionVisible.value = true
          conditionIndex.value = index
        }
      }
    }
    const closeConditionDescription = () => {
      conditionDescriptionVisible.value = false
      conditionIndex.value = -1
    }

    const newConditionVisible = ref(false)
    const startNewCondition = () => {
      if (!conditionDescriptionVisible.value){
        newConditionVisible.value = true
      }
    }

    const changeNotes = (event: any) => {
      store.commit('setNotes', event.target.value)
      store.dispatch('saveSheet')
    }

    return {
      name: computed(() => store.state.characterSheet.name),
      characterClass: computed(() => store.state.characterSheet.class),
      pronouns: computed(() => store.state.characterSheet.pronouns),
      notes: computed(() => store.state.characterSheet.notes),
      patch: computed(() => store.state.characterSheet.patch),
      trinket: computed(() => store.state.characterSheet.trinket ),
      editMode,
      changeName: (event: any) => { store.commit('setName', event.target.value) },
      changeClass: (event: any) => { store.commit('setClass', event.target.value) },
      changePronouns: (event: any) => { store.commit('setPronouns', event.target.value) },
      changeNotes,
      changePatch: (event: any) => { store.commit('setPatch', event.target.value) },
      changeTrinket: (event: any) => { store.commit('setTrinket', event.target.value) },
      viewConditionDescription,
      conditionDescriptionVisible,
      conditionIndex,
      closeConditionDescription,
      newConditionVisible,
      startNewCondition,
    }
  }
})

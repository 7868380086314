import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-605958f0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "condition-description" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Modal = _resolveComponent("Modal")!

  return (_openBlock(), _createBlock(_component_Modal, {
    visible: _ctx.visible && !_ctx.editMode,
    title: _ctx.condition?.name,
    onClose: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('close'))),
    color: "mediumpurple"
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.condition?.description), 1),
      _createElementVNode("button", {
        class: "modal-btn pink",
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.treatCondition && _ctx.treatCondition(...args)))
      }, _toDisplayString(_ctx.condition?.treatment < 2 ? 'Get Treatment' : 'Cure Condition'), 1)
    ]),
    _: 1
  }, 8, ["visible", "title"]))
}
const standardConditions = [
  {
    name: 'Coward',
    description: 'You must make a Fear Save to engage in violence or flee.',
  },
  {
    name: 'Nightmares',
    description: 'Sleep is difficult. You make Comfort Saves with Disadvantage.',
  },
  {
    name: 'Deflated',
    description: 'Whenever a Nearby crew-member fails a Save,  gain 1 Stress.',
  },
  {
    name: 'Doomed',
    description: 'You feel cursed and unlucky. All Critical Successes are instead Critical Failures.',
  },
  {
    name: 'Haunted',
    description: 'Something starts visiting you at night. ' +
      'In your dreams. Out of the corner of your eye. And soon it will start making demands.',
  },
  {
    name: 'Spiraling',
    description: 'You make Panic Checks with Disadvantage.',
  },
]

export default standardConditions

import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, vModelText as _vModelText, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-71a9211d"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Modal = _resolveComponent("Modal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Modal, {
      visible: _ctx.visible && !_ctx.editMode,
      title: "New Condition",
      onClose: _ctx.closeAll,
      color: "mediumpurple"
    }, {
      default: _withCtx(() => [
        _createElementVNode("button", {
          class: "modal-btn pink",
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.viewStandardConditions && _ctx.viewStandardConditions(...args)))
        }, " Standard "),
        _createElementVNode("button", {
          class: "modal-btn pink",
          onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.viewCustomCondition && _ctx.viewCustomCondition(...args)))
        }, " Custom ")
      ]),
      _: 1
    }, 8, ["visible", "onClose"]),
    _createVNode(_component_Modal, {
      visible: _ctx.customVisible && !_ctx.editMode,
      title: "Custom Condition",
      onClose: _cache[5] || (_cache[5] = ($event: any) => (_ctx.customVisible = false)),
      color: "mediumpurple"
    }, {
      default: _withCtx(() => [
        _withDirectives(_createElementVNode("input", {
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.customCondition.name) = $event)),
          placeholder: "Name"
        }, null, 512), [
          [_vModelText, _ctx.customCondition.name]
        ]),
        _withDirectives(_createElementVNode("textarea", {
          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.customCondition.description) = $event)),
          placeholder: "Description",
          class: "description-input"
        }, null, 512), [
          [_vModelText, _ctx.customCondition.description]
        ]),
        _createElementVNode("button", {
          class: "modal-btn pink",
          onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.gainCondition(_ctx.customCondition)))
        }, " Gain Condition ")
      ]),
      _: 1
    }, 8, ["visible"]),
    _createVNode(_component_Modal, {
      visible: _ctx.standardVisible && !_ctx.editMode,
      title: "Standard Condition",
      onClose: _cache[6] || (_cache[6] = ($event: any) => (_ctx.standardVisible = false)),
      color: "mediumpurple"
    }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.StandardConditions, (condition) => {
          return (_openBlock(), _createElementBlock("button", {
            class: "modal-btn pink",
            onClick: ($event: any) => (_ctx.gainCondition(condition))
          }, _toDisplayString(condition.name), 9, _hoisted_1))
        }), 256))
      ]),
      _: 1
    }, 8, ["visible"])
  ], 64))
}
import { resolveComponent as _resolveComponent, createVNode as _createVNode, vModelText as _vModelText, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, Fragment as _Fragment } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DropDown = _resolveComponent("DropDown")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_DropDown, {
      label: "Skill Level",
      options: ['Trained', 'Expert', 'Master'],
      onChange: _cache[0] || (_cache[0] = ($event: any) => (_ctx.levelChange($event))),
      color: "#ffe7bd"
    }),
    _createVNode(_component_DropDown, {
      label: "Skill",
      options: _ctx.skillOptions,
      onChange: _cache[1] || (_cache[1] = ($event: any) => (_ctx.skillChange($event))),
      color: "#ffe7bd"
    }, null, 8, ["options"]),
    (_ctx.showTextInput)
      ? _withDirectives((_openBlock(), _createElementBlock("input", {
          key: 0,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.skill) = $event))
        }, null, 512)), [
          [_vModelText, _ctx.skill]
        ])
      : _createCommentVNode("", true),
    _createElementVNode("button", {
      onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.trainSkill && _ctx.trainSkill(...args)))
    }, " Train Skill ")
  ], 64))
}

import { defineComponent, onMounted, ref } from 'vue'
import { useStore } from 'vuex'
import StarField from "@/components/StarField.vue";

export default defineComponent({
  components: {StarField},
  setup() {
    const store = useStore()

    const initializeGoogle = () => {
      try {
        // @ts-ignore
        google.accounts.id.initialize({
          client_id: '909232790544-i5v65iopcd352ui4a4ugrp5g7d1vnif5.apps.googleusercontent.com',
          callback: (response) => {
            store.commit('storeGoogleToken', response.credential)
            store.dispatch('signIn')
          }
        });
      } catch {
        setTimeout(initializeGoogle,50)
      }
    }
    initializeGoogle()

    const button = ref(null)
    onMounted(() => {
      const createLoginButton = () => {
        try{
          // @ts-ignore
          google.accounts.id.renderButton(
            button.value,
            {
              //theme: 'filled_black',
              text: 'signin',
            });
        } catch {
          setTimeout(createLoginButton, 50)
        }
      }
      createLoginButton()
    })

    return {
      button
    }
  }
})


import { defineComponent, computed } from 'vue'
import { useStore } from 'vuex'
import Modal from '@/components/Modal.vue'

export default defineComponent({
  components: {
    Modal,
  },
  props: {
    index: {
      type: Number,
      required: true,
    },
    visible: {
      type: Boolean,
      required: true,
    }
  },
  emits: [
    'close'
  ],
  //@ts-ignore
  setup(props: { index:number, visible:boolean }, {emit}) {
    const store = useStore()
    const editMode = computed(() => store.state.editMode)
    const condition = computed(() => store.state.characterSheet.conditions[props.index])

    const treatCondition = () => {
      if (condition.value.treatment === 2){
        emit('close')
      }
      store.commit('treatCondition', props.index)
      store.dispatch('saveSheet')
    }

    return {
      condition,
      editMode,
      treatCondition,
    }
  },
})


import { defineComponent, computed } from 'vue'
import { useStore } from 'vuex'

export default defineComponent({
  setup(){
    const store = useStore()

    return {
      editMode: computed(() => store.state.editMode ),
      traumaResponse: computed(() => store.state.characterSheet.stress.traumaResponse),
      editTraumaResponse: (event: any) => { store.commit("setTraumaResponse", event.target.value)}
    }
  }
})

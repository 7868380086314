
import {defineComponent} from 'vue'

export default defineComponent({
  props:{
    label: String,
    dark: {
      type: Boolean,
      default: false
    }
  }
})

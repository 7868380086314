
import {computed, defineComponent, ref} from 'vue'
import { useStore } from 'vuex'
import Modal from '@/components/Modal.vue'
import EquipmentTypes from '@/enums/equiptment-types'
import StandardItems from '@/assets/json/standard-items'

export default defineComponent({
  components: {
    Modal,
  },
  props: {
    suppressModals: {
      type: Boolean,
      required: true,
    }
  },
  emits: [
    'modalOpen'
  ],
  setup(props, { emit }){
    const store = useStore()
    const editMode = computed( () => store.state.editMode )
    const wearing = computed( () => store.state.characterSheet.equipment.wearing )
    const equipped = computed(() => store.state.characterSheet.equipment.equipped)
    const equippedArmorIndexes = computed(() => [...equipped.value.keys()].filter(index => equipped.value[index].type === EquipmentTypes.ARMOR))
    const locker = computed(() => store.state.characterSheet.equipment.locker )
    const lockerArmorIndexes = computed(() => [...locker.value.keys()].filter(index => locker.value[index].type === EquipmentTypes.ARMOR))

    // Description and Empty Modal
    const emptyArmorModalOpen = ref(false)
    const wearingModalOpen = ref(false)
    const openWearingModal = () => {
      if (!stripModalOpen.value && !props.suppressModals){
        if (wearing.value?.name) {
          wearingModalOpen.value = true
        } else {
          emptyArmorModalOpen.value = true
        }
        emit('modalOpen', true)
      }
    }

    const repair = () => {
      store.commit('repairArmor')
      store.dispatch('saveSheet')
      closeAllModals()
    }

    // Strip Modal
    const stripModalOpen = ref(false)
    const openStripModal = (event: Event) => {
      if (!wearingModalOpen.value && !props.suppressModals) {
        event.stopPropagation()
        stripModalOpen.value = true
      }
      emit('modalOpen', true)
    }
    const dropArmor = () => {
      store.commit('dropArmor')
      store.dispatch('saveSheet')
      closeAllModals()
    }
    const storeArmor = () => {
      store.commit('storeArmor')
      store.dispatch('saveSheet')
      closeAllModals()
    }

    // New Armor Modal
    const newArmorModalOpen = ref(false)
    const openNewArmorModal = () => {
      newArmorModalOpen.value = true
    }

    // Custom Armor Modal
    const customArmorModalOpen = ref(false)
    const customArmor : any = ref({})

    const openCustomArmorModal = () => {
      customArmorModalOpen.value = true
      customArmor.value = {
        name: '',
        description: '',
        armor: 0,
        type: EquipmentTypes.ARMOR,
      }
    }
    const validateArmor = (event) => {
      let value = event.target.value
      if (value < 0) {
        customArmor.value.armor = 0
      } else if ( value > 99 ){
        customArmor.value.armor = 99
      }
    }

    // Inventory Modal
    const armorInventoryModalOpen = ref(false)
    const openArmorInventoryModal = () => {
      armorInventoryModalOpen.value = true
    }
    const closeArmorInventoryModal = () => {
      armorInventoryModalOpen.value = false
    }
    const putOnInventoryArmor = (index: number) => {
      store.commit('putOnArmor', equipped.value[index])
      store.commit('drop', index)
      store.dispatch('saveSheet')
      closeAllModals()
    }

    // Storage Modal
    const armorStorageModalOpen = ref(false)
    const openArmorStorageModal = () => {
      armorStorageModalOpen.value = true
    }
    const closeArmorStorageModal = () => {
      armorStorageModalOpen.value = false
    }
    const putOnStorageArmor = (index: number) => {
      store.commit('putOnFromLocker', index)
      store.dispatch('saveSheet')
      closeAllModals()
    }

    // Standard Armor Select Modal
    const standardArmorModalOpen = ref(false)
    const openStandardArmorModal = () => {
      standardArmorModalOpen.value = true
    }
    const closeStandardArmorModal = () => {
      standardArmorModalOpen.value = false
    }

    const putOnArmor = (armor) => {
      store.commit('putOnArmor', armor)
      store.dispatch('saveSheet')
      closeAllModals()
    }

    const closeAllModals = () => {
      wearingModalOpen.value = false
      stripModalOpen.value = false
      newArmorModalOpen.value = false
      emptyArmorModalOpen.value = false
      customArmorModalOpen.value = false
      closeArmorInventoryModal()
      closeArmorStorageModal()
      closeStandardArmorModal()
      emit('modalOpen', false)
    }

    return {
      editMode,
      wearing,
      equipped,
      equippedArmorIndexes,
      locker,
      lockerArmorIndexes,
      EquipmentTypes,
      stripModalOpen,
      armorStorageModalOpen,
      armorInventoryModalOpen,
      newArmorModalOpen,
      customArmorModalOpen,
      wearingModalOpen,
      emptyArmorModalOpen,
      standardArmorModalOpen,
      customArmor,
      standardArmor: StandardItems.Armor,
      openWearingModal,
      openStripModal,
      openNewArmorModal,
      openCustomArmorModal,
      openArmorInventoryModal,
      openArmorStorageModal,
      openStandardArmorModal,
      closeArmorInventoryModal,
      closeArmorStorageModal,
      closeStandardArmorModal,
      dropArmor,
      storeArmor,
      putOnArmor,
      putOnInventoryArmor,
      putOnStorageArmor,
      closeAllModals,
      validateArmor,
      repair,
    }
  }
})

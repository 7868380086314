import EquipmentTypes from '@/enums/equiptment-types'

const defaultSheet = {
  highScore: 0,
  name: '',
  pronouns: '',
  class: '',
  trinket: '',
  patch: '',
  credits: 0,
  health: {
    maxHealth: 0,
    current: 0,
    maxWounds: 2,
    wounds: 2,
  },
  armor: 0,
  stats: {
    strength: 0,
    speed: 0,
    intellect: 0,
    combat: 0,
  },
  saves: {
    fear: 0,
    body: 0,
    sanity: 0,
  },
  stress: {
    minimum: 2,
    current: 2,
    traumaResponse: '',
  },
  notes: '',
  skills: {
    trained: [
    ],
    expert: [
    ],
    master: [
    ],
    inProgress: {
      skill: '',
      level: '',
      training: 0,
    }
  },
  equipment: {
    equipped: [
      {
        type: EquipmentTypes.EMPTY,
      },
    ],
    locker: [],
    wearing: {
    },
  },
  conditions: []
}

export default defaultSheet

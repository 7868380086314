<template>
  <router-view v-slot="{ Component }">
    <suspense>
      <component :is="Component" />
    </suspense>
  </router-view>
</template>

<style lang="stylus">
  .plain-text
    font-family 'M PLUS Rounded 1c'

  button, select
    @extend .plain-text

  body
    margin 0
    font-family Audiowide
    height 100vh
    width 100vw
    box-sizing border-box
    user-select none
    overflow:hidden
    &::-webkit-scrollbar
      display none

  input, textarea
    font-family 'Swanky And Moo Moo'
    color blue
    transition all .5s
    &[type=number]
      //font-family 'Shadows Into Light'
    &:disabled
      color black
      background-color white
      pointer-events none
    &:focus
      outline none
    &::-webkit-scrollbar
      display none

  div
    &::-webkit-scrollbar
      display none

  textarea
    resize: none

  #app
    height 100%

  button:not(:disabled), .button:not(.disabled)
    cursor pointer
    &:active
      filter brightness(90%)


  // These hide the number spinners
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button
    -webkit-appearance none
    margin 0
    input[type=number]
      -moz-appearance:textfield /* Firefox */
</style>


import { defineComponent, computed } from 'vue'
import { useStore } from 'vuex'
import LabeledBox from '@/components/LabeledBox.vue'
import HealthBars from '@/components/HealthBars.vue'
import Armor from '@/components/Armor.vue'

export default defineComponent({
  components: {
    LabeledBox,
    HealthBars,
    Armor
  },
  setup(){
    const store = useStore()
    const maxHealth = computed(() => store.state.characterSheet.health.maxHealth)
    const maxWounds = computed(() => store.state.characterSheet.health.maxWounds)

    const decreaseMaxHealth = () => {
      store.commit('decrementMaxHealth')
    }
    const disableDecreaseMaxHealth = () : string => {
      if (maxHealth.value <= 1) {
        return 'disabled'
      } else {
        return ''
      }
    }

    const increaseMaxHealth = () => {
      store.commit('incrementMaxHealth')
    }
    const disableIncreaseMaxHealth = () : string => {
      if (maxHealth.value >= 20) {
        return 'disabled'
      } else {
        return ''
      }
    }

    const increaseMaxWounds = () => {
      store.commit('increaseMaxWounds')
    }
    const disableIncreaseMaxWounds = () => {
      if (maxWounds.value === 4) {
        return 'disabled'
      } else {
        return ''
      }
    }

    const decreaseMaxWounds = () => {
      store.commit('decreaseMaxWounds')
    }
    const disableDecreaseMaxWounds = () => {
      if (maxWounds.value === 1) {
        return 'disabled'
      } else {
        return ''
      }
    }

    return {
      editMode: computed(() => store.state.editMode),
      decreaseMaxHealth,
      disableDecreaseMaxHealth,
      increaseMaxHealth,
      disableIncreaseMaxHealth,
      increaseMaxWounds,
      decreaseMaxWounds,
      disableDecreaseMaxWounds,
      disableIncreaseMaxWounds,
    }
  }
})

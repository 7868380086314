import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-018aa89b"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "column" }
const _hoisted_2 = ["id", "value", "disabled"]
const _hoisted_3 = ["for"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("input", {
      id: `${_ctx.name.toLowerCase()}-stat-bubble`,
      type: "number",
      value: _ctx.stat,
      disabled: !_ctx.editMode,
      onInput: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.save && _ctx.save(...args)))
    }, null, 40, _hoisted_2),
    _createElementVNode("label", {
      for: `${_ctx.name.toLowerCase()}-stat-bubble`
    }, _toDisplayString(_ctx.name), 9, _hoisted_3)
  ]))
}

import { defineComponent, computed } from 'vue'
import { useStore } from 'vuex'

export default defineComponent({
  setup() {
    const store = useStore()
    const editMode = computed(() => store.state.editMode )

    const increment = () => {
      if(!editMode.value) {
        store.commit('incrementHighScore')
        store.dispatch('saveSheet')
      }
    }

    return {
      editMode,
      highScore: computed(() => store.state.characterSheet.highScore),
      increment,
      changeHighScore: (event) => store.commit('changeHighScore', event.target.value),
    }
  }
})


import {defineComponent, computed, ref, onBeforeUnmount} from 'vue'
import LabeledBox from '@/components/LabeledBox.vue'
import {useStore} from 'vuex'

export default defineComponent({
  components: {LabeledBox},
  setup() {
    const store = useStore()
    const editMode = computed(() => store.state.editMode)
    const maxHealth = computed(() => store.state.characterSheet.health.maxHealth )
    const maxWounds = computed(() => store.state.characterSheet.health.maxWounds )
    const health = computed(() => store.state.characterSheet.health.current )
    const wounds = computed(() => store.state.characterSheet.health.wounds )
    const armor = computed(() => store.state.characterSheet.armor)
    let radioArray = ref<boolean[][]>([])

    //TODO: convert this to a computed value?
    const buildRadioArray = (): void => {
      const newArray: boolean[][] = []
      for (let wound = 1; wound <= maxWounds.value; wound++) {
        const woundArray: boolean[] = []
        if (wound > wounds.value) {
          for (let i = 0; i < maxHealth.value; i++) {
            woundArray.push(false)
          }
        } else if (wound === wounds.value) {
          for (let i = 0; i < maxHealth.value; i++) {
            if (i < health.value) {
              woundArray.push(true)
            } else {
              woundArray.push(false)
            }
          }
        } else {
          for (let i = 0; i < maxHealth.value; i++) {
            woundArray.push(true)
          }
        }
        newArray.push(woundArray)
      }
      radioArray.value = newArray
    }

    const clickRadio = (bool: boolean, wound: number): void => {
      if (editMode.value) {
        return
      }

      if (wound <= wounds.value) {
        if (bool === true) {
          store.commit('decrementHealth')
        } else {
          store.commit('incrementHealth')
        }
      }
      if (health.value === 0) {
        store.commit('decrementWounds')
        if (wounds.value > 0) {
          store.commit('maxOutHealth')
        }
      }
      store.dispatch('saveSheet')
      buildRadioArray()
    }

    const clickWound = (woundNumber: number): void => {
      if (editMode.value){
        if(wounds.value === 0){
          store.commit('incrementWounds')
          store.commit('setHealth', 1)
        } else if (woundNumber > wounds.value){
          store.commit('incrementWounds')
        } else {
          // we only clear away health if you clicked the current wound
          // otherwise, we assume you wanted to keep your current health
          if (woundNumber === wounds.value) {
            store.commit('maxOutHealth')
          }
          store.commit('decrementWounds')
        }
      }
      buildRadioArray()
    }

    const healthButtonClasses = (filled: boolean, wound: number) : string => {
      let classes = ''

      if (
        editMode.value
        || (armor.value > 0 && filled)
        || wound > wounds.value
      ) {
        classes += ' disabled '
      }

      if (filled) {
        classes += ' filled '
      }

      return classes
    }

    buildRadioArray()

    const unsub = store.subscribe((mutation, state) => {
      if (
        mutation.type === 'incrementMaxHealth'
        || mutation.type === 'decrementMaxHealth'
        || mutation.type === 'increaseMaxWounds'
        || mutation.type == 'decreaseMaxWounds'
        || mutation.type == 'revive'
      ){
        buildRadioArray()
      }
    })

    onBeforeUnmount(()=>{
      unsub()
    })

    return {
      clickRadio,
      clickWound,
      healthButtonClasses,
      wounds,
      radioArray,
      editMode
    }
  }
})


import { defineComponent, computed, ref } from 'vue'
import { useStore } from 'vuex'
import Modal from '@/components/Modal.vue'
import StandardConditions from '@/assets/json/standard-conditions'

export default defineComponent({
  components: {
    Modal,
  },
  props: {
    visible: {
      type: Boolean,
      required: true,
    }
  },
  emits: [
    'close'
  ],
  //@ts-ignore
  setup(props: { index:number, visible:boolean }, { emit }) {
    const store = useStore()
    const editMode = computed(() => store.state.editMode)

    const gainCondition = (condition) => {
      if ( condition.name ){
        store.commit('gainCondition', condition)
        store.dispatch('saveSheet')
        closeAll()
      }
    }

    const standardVisible = ref(false)
    const customVisible = ref(false)
    const viewStandardConditions = () => {
      if (!customVisible.value){
        standardVisible.value = true
      }
    }
    const viewCustomCondition = () => {
      if (!customVisible.value){
        customVisible.value = true
      }
    }
    const closeAll = () =>{
      standardVisible.value = false
      customVisible.value = false
      customCondition.value = {
        name: '',
        description: '',
      }
      emit('close')
    }

    const customCondition = ref({
      name: '',
      description: '',
    })

    return {
      editMode,
      customVisible,
      standardVisible,
      customCondition,
      StandardConditions,
      gainCondition,
      viewCustomCondition,
      viewStandardConditions,
      closeAll,
    }
  },
})

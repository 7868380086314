import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-dbe2a450"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["for-"]
const _hoisted_2 = ["id"]
const _hoisted_3 = ["value"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (!_ctx.hideLabel)
      ? (_openBlock(), _createElementBlock("label", {
          key: 0,
          "for-": `${_ctx.label.toLowerCase().replace(/\s+/g,'')}-dropdown`
        }, _toDisplayString(_ctx.label), 9, _hoisted_1))
      : _createCommentVNode("", true),
    _createElementVNode("select", {
      id: `${_ctx.label.toLowerCase().replace(/\s+/g,'')}-dropdown`,
      onChange: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('change',$event.target.value))),
      style: _normalizeStyle({backgroundColor: _ctx.color})
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (option) => {
        return (_openBlock(), _createElementBlock("option", { value: option }, _toDisplayString(option), 9, _hoisted_3))
      }), 256))
    ], 44, _hoisted_2)
  ], 64))
}

import {computed, defineComponent, ref} from 'vue'
import {useStore} from 'vuex'
import Header from '@/components/Header.vue'
import Modal from '@/components/Modal.vue'
import EquipmentButton from '@/components/EquipmentButton.vue'
import EquipmentTypes from '@/enums/equiptment-types'
import StandardItems from '@/assets/json/standard-items'
import DropDown from '@/components/DropDown.vue'
import Wearing from '@/components/Wearing.vue'

export default defineComponent({
  components: {
    DropDown,
    Header,
    Modal,
    EquipmentButton,
    Wearing,
  },
  setup(){
    const store = useStore()
    const editMode = computed( () => store.state.editMode )
    const carryCapacity = computed( () => store.state.characterSheet.equipment.equipped.length )
    const equipped = computed( () => store.state.characterSheet.equipment.equipped )

    const displayCapacityMinus = () : boolean => {
      const emptyIndex = equipped.value.findIndex(item => item.type === EquipmentTypes.EMPTY)
      if ( !editMode.value || emptyIndex === -1 || equipped.value.length < 1) {
        return false
      } else {
        return true
      }
    }

    const clickCapacityMinus = () => {
      store.commit('decrementCapacity')
    }

    const displayCapacityPlus = () : boolean => {
      if (!editMode.value || equipped.value.length >= 10) {
        return false
      } else {
        return true
      }
    }

    const clickCapacityPlus = () => {
      store.commit('incrementCapacity')
    }

    const selectedItemIndex = ref<number | null>(null)

    const closeAllModals = () => {
      itemModalOpen.value = false
      dropModalOpen.value = false
      emptyModalOpen.value = false
      closeStandardItemModal()
      closeCustomItemModal()
      closeItemSelectModal()
      closeStorageModal()
      closeNewItemModal()
      selectedItemIndex.value = null
    }

    const anyModalOpen = computed(() => {
      return ( itemModalOpen.value || dropModalOpen.value || emptyModalOpen.value )
    })

    let wearingModalOpen = false
    const wearingModalChange = (value) => {
      wearingModalOpen = value
    }

    // Item Description Modal
    const itemModalOpen = ref(false)
    const openItemModal = (index: number) => {
      // this check prevents switching while a modal is open
      // cant truthy check cuz zero index would be falsy
      if ( selectedItemIndex.value === null && !wearingModalOpen ){
        selectedItemIndex.value = index
        itemModalOpen.value = true
      }
    }

    const repairArmor = (index) => {
      store.commit("repairInventoryArmor", index)
      store.dispatch('saveSheet')
      closeAllModals()
    }

    // Drop Item Modal
    const dropModalOpen = ref(false)
    const openDropModal = (index: number) => {
      if ( selectedItemIndex.value === null && !wearingModalOpen ){
        selectedItemIndex.value = index
        dropModalOpen.value = true
      }
    }
    const dropItem = () => {
      if ( selectedItemIndex.value !== null ){
        store.commit('drop', selectedItemIndex.value)
        store.dispatch('saveSheet')
      }
      closeAllModals()
    }
    const storeItem = () => {
      if (selectedItemIndex.value !== null){
        store.commit('storeItem', selectedItemIndex.value)
        store.dispatch('saveSheet')
      }
      closeAllModals()
    }

    // Empty Slot Modal
    const emptyModalOpen = ref(false)
    const openEmptyModal = (index: number) => {
      if (selectedItemIndex.value === null && !wearingModalOpen) {
        selectedItemIndex.value = index
        emptyModalOpen.value = true
      }
    }

    //Storage Modal
    const storageModalOpen = ref(false)
    const openStorageModal = () => {
      if(!newItemModalOpen.value){
        storageModalOpen.value = true
      }
    }
    const pickUpItemFromStorage = (index: number) => {
      store.commit('pickUpFromLocker', { lockerIndex: index, equippedIndex: selectedItemIndex.value})
      store.dispatch('saveSheet')
      closeAllModals()
    }
    const closeStorageModal = () => {
      storageModalOpen.value = false
    }

    // New Item Modal
    const newItemModalOpen = ref(false)
    const openNewItemModal = () => {
      if(!storageModalOpen.value){
        newItemModalOpen.value = true
      }
    }
    const closeNewItemModal = () =>{
      newItemModalOpen.value = false
    }

    // Standard Item Modal
    const standardItemModalOpen = ref(false)
    const openStandardItemModal = () => {
      standardItemModalOpen.value = true
    }
    const closeStandardItemModal = () => {
      standardItemModalOpen.value = false
    }

    // Custom Item Modal
    const customItemModalOpen = ref(false)
    const customItem : any = ref({})
    const openCustomItemModal = () => {
      customItem.value = {
        type: EquipmentTypes.BASIC,
        name: '',
        description: '',
      }
      if (!standardItemModalOpen.value) {
        customItemModalOpen.value = true
      }
    }
    const closeCustomItemModal = () => {
      customItemModalOpen.value = false
    }
    const changeCustomItemType = (type: string) => {
      customItem.value = {
        name: customItem.value.name,
        description: customItem.value.description,
        type: EquipmentTypes.BASIC
      }

      switch (type) {
        case 'Basic':
          customItem.value.type = EquipmentTypes.BASIC
          break
        case 'Gun':
          customItem.value.type = EquipmentTypes.GUN
          customItem.value.maxUses = 5
          break
        case 'Armor':
          customItem.value.type = EquipmentTypes.ARMOR
          customItem.value.armor = 3
          break
        case 'Consumable':
          customItem.value.type = EquipmentTypes.USES
          customItem.value.maxUses = 3
          break
      }
    }

    const validateMaxUses = (event) => {
      let value = event.target.value
      if (value < 1) {
        customItem.value.maxUses = 1
      } else if (value > 99) {
        customItem.value.maxUses = 99
      }
      if (customItem.type === EquipmentTypes.USES) {
        customItem.value.uses = customItem.value.maxUses
      }
    }

    const validateArmor = (event) => {
      let value = event.target.value
      if (value < 0) {
        customItem.value.armor = 0
      } else if ( value > 99 ){
        customItem.value.armor = 99
      }
    }

    // Item Select Modal
    const itemSelectModalOpen = ref(false)
    const selectItems = ref([])
    const itemSelectModalTitle = ref('')
    const openItemSelectModal = (type: string) => {
      if (!itemSelectModalOpen.value) {
        itemSelectModalTitle.value = type
        selectItems.value = StandardItems[type].sort((a, b) => {
          if (a.name < b.name) {
            return -1;
          } else if (a.name > b.name) {
            return 1;
          } else {
            return 0;
          }
        })
        itemSelectModalOpen.value = true
      }
    }
    const closeItemSelectModal = () => {
      selectItems.value = []
      itemSelectModalOpen.value = false
    }
    const pickUpItem = (item) => {
      switch (item.type) {
        case EquipmentTypes.GUN:
          item.uses = 0
          break
        case EquipmentTypes.USES:
          item.uses = item.maxUses
          break
        case EquipmentTypes.AMMO:
          item.uses = 3
      }
      store.commit('acquireItem', { item: item, equippedIndex: selectedItemIndex.value })
      store.dispatch('saveSheet')
      closeAllModals()
    }

    const updateCredits = (event) => {
      store.commit('setCredits', event.target.value)
      store.dispatch('saveSheet')
    }

    return {
      EquipmentTypes,
      carryCapacity,
      editMode,
      equipped,
      selectedItemIndex,
      itemModalOpen,
      dropModalOpen,
      emptyModalOpen,
      storageModalOpen,
      newItemModalOpen,
      standardItemModalOpen,
      customItemModalOpen,
      itemSelectModalOpen,
      selectItems,
      itemSelectModalTitle,
      customItem,
      credits: computed(() => store.state.characterSheet.credits),
      updateCredits,
      displayCapacityPlus,
      clickCapacityPlus,
      displayCapacityMinus,
      clickCapacityMinus,
      locker: computed(() => store.state.characterSheet.equipment.locker),
      closeAllModals,
      openItemModal,
      openDropModal,
      openEmptyModal,
      openStorageModal,
      closeStorageModal,
      pickUpItemFromStorage,
      dropItem,
      storeItem,
      openNewItemModal,
      closeNewItemModal,
      openStandardItemModal,
      closeStandardItemModal,
      openCustomItemModal,
      closeCustomItemModal,
      changeCustomItemType,
      validateMaxUses,
      validateArmor,
      openItemSelectModal,
      closeItemSelectModal,
      pickUpItem,
      anyModalOpen,
      wearingModalChange,
      repairArmor,
      index2: (n: number): number => { return Math.floor(carryCapacity.value / 2) + n - 1 },
    }
  },
})

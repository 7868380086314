// @ts-nocheck
// TODO: Why is the type based on initial state???

import {createStore} from 'vuex'
import axios from 'axios'
import env from '@/env.js'
import EquipmentTypes from '@/enums/equiptment-types'
import defaultSheet from '@/assets/json/default-sheet'
import router from "@/router";

export default createStore({
  state: {
    googleToken: '',
    authToken: '',
    characterSheet: defaultSheet,
    editMode: false,
  },
  mutations: {
    storeGoogleToken(state, credential){
      state.googleToken = credential
      const payload = JSON.parse(atob(credential.split('.')[1]))
      // @ts-ignore
      state.playerName = `${payload.given_name} ${payload.family_name}`
    },
    storeAuthToken(state, token){
      state.authToken = token
    },
    signOut(state){
      state.authToken = ''
      state.googleToken = ''
    },
    toggleEditMode(state){
      state.editMode = !state.editMode
    },
    setSheet(state, sheet){
      state.characterSheet = sheet
    },
    incrementHighScore(state) {
      state.characterSheet.highScore++
    },
    changeHighScore(state, value) {
      const score = parseInt(value)
      if (score > 100) {
        state.characterSheet.highScore = 99
      } else if (score === NaN || score < 0) {
        state.characterSheet.highScore = 0
      } else {
        state.characterSheet.highScore = score
      }
    },

    // Personal Details
    setName(state, value){
      state.characterSheet.name = value
    },
    setPronouns(state, value){
      state.characterSheet.pronouns = value
    },
    setClass(state, value){
      state.characterSheet.class = value
    },
    setCredits(state, value){
      const number = parseInt(value, 10)
      state.characterSheet.credits = number
    },
    setNotes(state,value){
      state.characterSheet.notes = value
    },
    setPatch(state, value) {
      state.characterSheet.patch = value
    },

    // Conditions
    treatCondition(state, conditionIndex){
      state.characterSheet.conditions[conditionIndex].treatment++
      if (state.characterSheet.conditions[conditionIndex].treatment === 3){
        state.characterSheet.conditions.splice(conditionIndex, 1)
      }
    },
    gainCondition(state, condition){
      const conditionCopy = JSON.parse(JSON.stringify(condition))
      conditionCopy.treatment = 0
      state.characterSheet.conditions.push(conditionCopy)
    },
    undoTreatment(state, index){
      if (state.characterSheet.conditions[index].treatment > 0) {
        state.characterSheet.conditions[index].treatment--
      }
    },

    // Health
    setHealth(state, value: number){
      let number = Math.round(Number(value))
      if (!number || number < 0) {
        number = 0
      }
      if (number > state.characterSheet.health.maxHealth) {
        state.characterSheet.health.current = state.characterSheet.health.maxHealth
      } else {
        state.characterSheet.health.current = number
      }
    },
    incrementHealth(state){
      if (state.characterSheet.health.current < state.characterSheet.health.maxHealth) {
        state.characterSheet.health.current++
      }
    },
    decrementHealth(state){
      if (state.characterSheet.armor === 0 && state.characterSheet.health.current > 0) {
        state.characterSheet.health.current--
      }
    },
    maxOutHealth(state){
      state.characterSheet.health.current = state.characterSheet.health.maxHealth
    },
    incrementWounds(state){
      if (state.characterSheet.health.wounds < state.characterSheet.health.maxWounds){
        state.characterSheet.health.wounds++
      }
    },
    decrementWounds(state){
      if (state.characterSheet.health.wounds > 0){
        state.characterSheet.health.wounds--
        if (state.characterSheet.health.wounds === 0) {
          state.characterSheet.health.current = 0
        }
      }
    },
    increaseMaxWounds(state){
      if (state.characterSheet.health.maxWounds < 4) {
        state.characterSheet.health.maxWounds++
        state.characterSheet.health.wounds++
      }
    },
    decreaseMaxWounds(state){
      if (state.characterSheet.health.maxWounds > 1) {
        state.characterSheet.health.maxWounds--
        if (state.characterSheet.health.wounds > state.characterSheet.health.maxWounds) {
          state.characterSheet.health.wounds = state.characterSheet.health.maxWounds
        }
      }
    },
    incrementMaxHealth(state){
      if (state.characterSheet.health.maxHealth < 20) {
        state.characterSheet.health.maxHealth++
      }
    },
    decrementMaxHealth(state){
      if (state.characterSheet.health.maxHealth > 1) {
        state.characterSheet.health.maxHealth--
        if (state.characterSheet.health.current > state.characterSheet.health.maxHealth){
          state.characterSheet.health.current = state.characterSheet.health.maxHealth
        }
      }
    },
    revive(state){
      if(state.characterSheet.health.wounds === 0){
        state.characterSheet.health.wounds = 1
        state.characterSheet.health.current = 1
      }
    },

    // Armor
    breakArmor(state){
      state.characterSheet.armor = 0
      if (state.characterSheet.equipment.wearing) {
        state.characterSheet.equipment.wearing.broken = true
      }
    },
    setArmor(state, value: number){
      let number = Math.round(Number(value))
      if (!number || number < 0) {
        number = 0
      }
      if (number > 99){
        number = 99
      }
      if (number >= 0){
        state.characterSheet.armor = number
      }
    },
    dropArmor(state) {
      state.characterSheet.equipment.wearing = null
      state.characterSheet.armor = 0
    },
    storeArmor(state) {
      state.characterSheet.equipment.locker.push(state.characterSheet.equipment.wearing)
      state.characterSheet.equipment.wearing = null
      state.characterSheet.armor = 0
    },
    putOnArmor(state, armor){
      state.characterSheet.equipment.wearing = JSON.parse(JSON.stringify(armor))
      if (!armor.broken) {
        state.characterSheet.armor = armor.armor
      }
    },
    putOnFromLocker(state, lockerIndex){
      if (state.characterSheet.equipment.locker[lockerIndex].type = EquipmentTypes.ARMOR){
        state.characterSheet.equipment.wearing = state.characterSheet.equipment.locker[lockerIndex]
        state.characterSheet.equipment.locker.splice(lockerIndex, 1)
        if (!state.characterSheet.equipment.wearing.broken){
          state.characterSheet.armor = state.characterSheet.equipment.wearing.armor
        }
      }
    },
    repairArmor(state){
      if (state.characterSheet.equipment.wearing?.broken) {
        state.characterSheet.equipment.wearing.broken = false
        state.characterSheet.armor = state.characterSheet.equipment.wearing.armor
      }
    },
    repairInventoryArmor(state, index : number){
      // @ts-ignore
      if (state.characterSheet.equipment.equipped[index]?.broken) {
        // @ts-ignore
        state.characterSheet.equipment.equipped[index].broken = false
      }
    },

    // Stress
    incrementCurrentStress(state){
      if (state.characterSheet.stress.current < 20){
        state.characterSheet.stress.current++
      }
    },
    decrementCurrentStress(state){
      if (state.characterSheet.stress.current > state.characterSheet.stress.minimum) {
        state.characterSheet.stress.current--
      }
    },
    setTraumaResponse(state, value) {
      state.characterSheet.stress.traumaResponse = value
    },
    setMinStress(state, value: number) {
      let number = Math.round(Number(value))
      if (!number || number < 0) {
        number = 0
      }

      if (number > 10) {
        state.characterSheet.stress.minimum = 10
      } else {
        state.characterSheet.stress.minimum = number
      }

      if (state.characterSheet.stress.minimum > state.characterSheet.stress.current){
        state.characterSheet.stress.current = state.characterSheet.stress.minimum
      }
    },

    // Stats
    setIntellect(state, value: number){
      let number = Math.round(Number(value))
      if (!number || number < 0) {
        number = 0
      } else if (number > 99){
        number = 99
      }
      state.characterSheet.stats.intellect = number
    },
    setStrength(state, value: number){
      let number = Math.round(Number(value))
      if (!number || number < 0) {
        number = 0
      } else if (number > 99){
        number = 99
      }
      state.characterSheet.stats.strength = number
    },
    setSpeed(state, value: number){
      let number = Math.round(Number(value))
      if (!number || number < 0) {
        number = 0
      } else if (number > 99){
        number = 99
      }
      state.characterSheet.stats.speed = number
    },
    setCombat(state, value: number){
      let number = Math.round(Number(value))
      if (!number || number < 0) {
        number = 0
      } else if (number > 99){
        number = 99
      }
      state.characterSheet.stats.combat = number
    },

    // Saves
    setSanity(state, value: number){
      let number = Math.round(Number(value))
      if (!number || number < 0) {
        number = 0
      } else if (number > 99){
        number = 99
      }
      state.characterSheet.saves.sanity = number
    },
    setFear(state, value: number){
      let number = Math.round(Number(value))
      if (!number || number < 0) {
        number = 0
      } else if (number > 99){
        number = 99
      }
      state.characterSheet.saves.fear = number
    },
    setBody(state, value: number){
      let number = Math.round(Number(value))
      if (!number || number < 0) {
        number = 0
      } else if (number > 99){
        number = 99
      }
      state.characterSheet.saves.body = number
    },

    // Skills
    incrementSkillTraining(state){
      if (state.characterSheet.skills.inProgress?.training < 5 && state.characterSheet.skills.inProgress.skill){
        state.characterSheet.skills.inProgress.training++
        if (state.characterSheet.skills.inProgress.training === 5){
          state.characterSheet.skills[state.characterSheet.skills.inProgress.level].push(state.characterSheet.skills.inProgress.skill)
          state.characterSheet.skills.inProgress.skill = ""
          state.characterSheet.skills.inProgress.training = 0
          state.characterSheet.skills.inProgress.level = ""
        }
      }
    },
    deleteSkill(state, input: {skill: string, level: string}){
      const { skill, level } = input
      const index = state.characterSheet.skills[level].indexOf(skill)
      if (index > -1){
        state.characterSheet.skills[level].splice(index, 1)
      }
    },
    decrementSkillTraining(state){
      if(state.characterSheet.skills.inProgress.training > 0){
        state.characterSheet.skills.inProgress.training--
      }
    },
    cancelSkillTraining(state){
      state.characterSheet.skills.inProgress.skill = ""
      state.characterSheet.skills.inProgress.training = 0
      state.characterSheet.skills.inProgress.level = ""
    },
    trainSkill(state, input: {skill: string, level: string}){
      const { skill, level } = input
      state.characterSheet.skills.inProgress.skill = skill
      state.characterSheet.skills.inProgress.level = level
      state.characterSheet.skills.inProgress.training = 0
    },

    //Equipment
    incrementCapacity(state){
      if (state.characterSheet.equipment.equipped.length < 10) {
        state.characterSheet.equipment.equipped.push({
          type: EquipmentTypes.EMPTY
        })
      }
    },
    decrementCapacity(state){
      let index = state.characterSheet.equipment.equipped.findIndex(item => item.type === EquipmentTypes.EMPTY)
      if (index !== -1){
        state.characterSheet.equipment.equipped.splice(index, 1)
      }
    },
    drop(state, index: number){
      state.characterSheet.equipment.equipped[index] = {
        type: EquipmentTypes.EMPTY
      }
    },
    storeItem(state, index: number){
      state.characterSheet.equipment.locker.push(state.characterSheet.equipment.equipped[index])
      state.characterSheet.equipment.equipped[index] = {
        type: EquipmentTypes.EMPTY
      }
    },
    pickUpFromLocker(state, input: { lockerIndex: number, equippedIndex: number}){
      state.characterSheet.equipment.equipped[input.equippedIndex] = state.characterSheet.equipment.locker[input.lockerIndex]
      state.characterSheet.equipment.locker.splice(input.lockerIndex, 1)
    },
    acquireItem(state, input: { item: object, equippedIndex: number}){
      const itemCopy = JSON.parse(JSON.stringify(input.item))
      // @ts-ignore
      state.characterSheet.equipment.equipped[input.equippedIndex] = itemCopy
    },
    setTrinket(state, value: string){
      state.characterSheet.trinket = value
    },
    useItem(state, index: number){
      if (state.characterSheet.equipment.equipped[index].uses > 0){
        state.characterSheet.equipment.equipped[index].uses--
      }
      // if (state.characterSheet.equipment.equipped[index].type !== EquipmentTypes.GUN &&
      //   state.characterSheet.equipment.equipped[index].uses === 0){
      //   state.characterSheet.equipment.equipped.splice(index, 1)
      // }
    },
    reload(state, gunIndex: number){
      if (state.characterSheet.equipment.equipped[gunIndex].type === EquipmentTypes.GUN &&
        state.characterSheet.equipment.equipped[gunIndex].uses < state.characterSheet.equipment.equipped[gunIndex].maxUses
      ){
        const ammoIndex = state.characterSheet.equipment.equipped.findIndex(item => {
          return item.type === EquipmentTypes.AMMO && item.uses > 0
        })
        if(ammoIndex > -1){
          state.characterSheet.equipment.equipped[gunIndex].uses = state.characterSheet.equipment.equipped[gunIndex].maxUses
          state.characterSheet.equipment.equipped[ammoIndex].uses--
          // if(state.characterSheet.equipment.equipped[ammoIndex].uses === 0){
          //   state.characterSheet.equipment.equipped.splice(ammoIndex, 1)
          // }
        }
      }
    },
    setUses(state, input: {index: number, uses: number}){
      let number = Math.round(Number(input.uses))
      const item = state.characterSheet.equipment.equipped[input.index]
      if (!number || number < 0){
        number = 0
      } else if (number > 99){
        number = 99
      } else if (item.maxUses && number > item.maxUses){
        number = item.maxUses
      }
      state.characterSheet.equipment.equipped[input.index].uses = number
    },
  },
  actions: {
    async signIn({state, commit, dispatch}){
      const api = axios.create({
        baseURL: env.apiUrl
      })
      try {
        const response = await api.post('/sign-in', state.googleToken)
        if (response.data.token){
          commit('storeAuthToken', response.data.token)
          await router.push({name: 'sheet'})
        } else {
          console.error('No token returned.')
          dispatch('signOut')
        }
      } catch (error) {
        console.error('Sign-in Failed. Error: ' + JSON.stringify(error))
        if ( error.response?.status === 401) {
          dispatch('signOut')
        }
      }
    },
    async signOut({commit}){
      commit('signOut')
      await router.push({ name: 'landing' })
    },
    async getSheet({ state, commit, dispatch }){
      const api = axios.create({
        headers: {
          'Authorization' : `Bearer ${state.authToken}`
        },
        baseURL: env.apiUrl
      })
      try{
        const response = await api.get('/sheets')
        const sheets = response.data?.sheets
        if (sheets?.length > 0){
          commit('setSheet',sheets[0])
        } else {
          await router.push({name: 'wizard'})
        }
      } catch (error) {
        //TODO: probably need an error page...
        console.error('Failed to get character sheet. Error: ' + JSON.stringify(error))
        if ( error.response?.status === 401) {
          dispatch('signOut')
        }
      }
    },
    async createSheet({commit, state}, sheet){
      const api = axios.create({
        headers: {
          'Authorization' : `Bearer ${state.authToken}`
        },
        baseURL: env.apiUrl
      })
      commit('setSheet', sheet)
      await api.post('/sheets', sheet)
    },
    async saveSheet({ state, dispatch }){
      //TODO: api service
      const api = axios.create({
        headers: {
          'Authorization' : `Bearer ${state.authToken}`
        },
        baseURL: env.apiUrl
      })
      try {
        api.put('/sheets/0', state.characterSheet)
      } catch (error) {
        console.error('Failed to save character sheet. Error: ' + JSON.stringify(error))
        if ( error.response?.status === 401) {
          dispatch('signOut')
        }
      }
    },
    async overwriteSheet({ state, commit }, {id, sheet}){
      const api = axios.create({
        headers: {
          'Authorization' : `Bearer ${state.authToken}`
        },
        baseURL: env.apiUrl
      })
      commit('setSheet', sheet)
      await api.put(`/sheets/${id}`, sheet)
    },
    toggleEditMode({state, commit, dispatch}){
      if (state.editMode){
        dispatch('saveSheet')
      }
      commit('toggleEditMode')
    },
  },
  modules: {
  }
})


import { defineComponent, ref } from 'vue'
import { useStore } from 'vuex'
import DropDown from '@/components/DropDown.vue'
import skillTree from '@/assets/json/skills-tree'

export default defineComponent({
  components:{
    DropDown,
  },
  setup(){
    const store = useStore()
    let skillOptions = ref([])
    let level = 'trained'
    let skill = ref('')
    let showTextInput = ref(false)

    const skillChange = (newSkill) => {
      if (newSkill === "Custom") {
        skill.value = ''
        showTextInput.value = true
      } else {
        showTextInput.value = false
        skill.value = newSkill
      }
    }

    const levelChange = (newLevel) => {
      let prereqLevel = ''
      newLevel = newLevel.toLowerCase()
      level = newLevel
      switch (newLevel) {
        case 'expert':
          prereqLevel = 'trained'
          break
        case 'master':
          prereqLevel = 'expert'
          break
      }

      skillOptions.value = skillTree[newLevel].filter(skill => {
        if (store.state.characterSheet.skills[newLevel].includes(skill.name)){
          return false
        }
        if (!skill.prerequisites || skill.prerequisites.length === 0){
          return true
        }
        for (let prerequisite of skill.prerequisites){
          if (store.state.characterSheet.skills[prereqLevel].includes(prerequisite)){
            return true
          }
        }
        return false
      }).map(skill => skill.name)

      // TODO: one-off for Sophontology?

      skillOptions.value.push('Custom')
      skillChange(skillOptions.value[0])
    }
    // get initial list
    levelChange(level)

    const trainSkill = () => {
      store.commit('trainSkill', {skill, level})
      store.dispatch('saveSheet')
    }

    return {
      skillOptions,
      showTextInput,
      skill,
      levelChange,
      skillChange,
      trainSkill,
    }
  },
})

const patches = [
  '"I\'m Not A Rocket Scientist / But You\'re An Idiot"',
  'Medic Patch (Skull and Crossbones over Cross)',
  '"Don\'t Run You\'ll Only Die Tired" Backpatch',
  'Red Shirt Logo',
  'Blood Type (Reference Patch)',
  '"Do I LOOK Like An Expert?"',
  'Biohazard Symbol',
  'Mr. Yuck',
  'Nuclear Symbol',
  '"Eat The Rich"',
  '"Be Sure: Doubletap"',
  'Flame Emoji',
  'Smiley Face (Glow in the Dark)',
  '"Smile: Big Brother is Watching"',
  'Jolly Rodger',
  'Viking Skull',
  '"APEX PREDATOR" (Sabertooth Skull)',
  'Pin-Up Model (Ace of Spades)',
  'Queen of Hearts',
  'Security Guard',
  '"LONER"',
  'Front Towards Enemy (Claymore Mine)',
  'Pin-Up Model (Riding Missile)',
  'FUBAR',
  '"I\'m A (LOVE) Machine"',
  'Pin-Up Model (Mechanic)',
  'HELLO MY NAME IS:',
  '"Powered By Coffee"',
  '"Take Me To Your Leader" (UFO)',
  '"DO YOOUR JOB"',
  '"Take My Life (Please)"',
  '"Upstanding Citizen"',
  'Allergic to Bullshit (Medical Style Patch)',
  '"Fix Me First" (Caduceus)',
  '"I Like My Tools Clean / And My Lovers Dirty"',
  'Pin-Up Model (Nurse): "The Louder You Scream the Faster I Come"',
  'HMFIC (Head Mother Fucker In Charge)',
  'Dove in Crosshairs',
  'Chibi Cthulhu',
  '"Welcome to the DANGER ZONE"',
  'Skull and Crossed Wrenches',
  'Pin-Up Model (Succubus)',
  '"DILLIGAF?"',
  '"DRINK / FIGHT / FUCK"',
  '"Work Hard / Party Harder"',
  'Mudflap Girl',
  'Fun Meter (reads: Bad Time)',
  '"GAME OVER" (Bride & Groom)',
  'Heart',
  '"IMPROVE / ADAPT / OVERCOME"',
  '"SUCK IT UP"',
  '"Cowboy Up" (Crossed Revolvers)',
  '"Troubleshooter"',
  'NASA Logo',
  'Crossed Hammers with Wings',
  '"Keep Well Lubricated"',
  'Soviet Hammer & Sickle',
  '"Plays Well With Others"',
  '"Live Free and Die"',
  '"IF I\'M RUNNING KEEP UP" Backpatch',
  '"Meat Bag"',
  '"I Am Not A Robot"',
  'Red Gear',
  '"I Can\'t Fix Stupid"',
  '"Space IS My Home" (Sad Astronaut)',
  'All Seeing Eye',
  'Poker Hand: Dead Man\'s Hand (Aces Full of Eights)',
  '"All Out of Fucks To Give" (Astronaut with Turned Out Pockets)',
  '"Travel To Distant Places / Meet Unusual Things / Get Eaten"',
  'BOHICA (Bend Over Here It Comes Again)',
  '"I Am My Brother\'s Keeper"',
  '"Mama Tried"',
  'Black Widow Spider',
  '"My Other Ride Married You"',
  '"One Size Fits All" (Grenade)',
  'Grim Reaper Backpatch',
  'отъебись (Get Fucked, Russian)',
  '"Smooth Operator"',
  'Atom Symbol',
  '"For Science!"',
  '"Actually, I AM A Rocket Scientist"',
  '"Help Wanted"',
  'Princess',
  '"NOMAD"',
  '"GOOD BOY"',
  'Dice (Snake Eyes)',
  '"#1 Worker"',
  '"GOOD" (Brain)',
  '"Bad Bitch"',
  '"Too Pretty To Die"',
  '"Fuck Forever" (Roses)',
  'Icarus',
  '"Girl\'s Best Friend" (Diamond)',
  'Risk of Electrocution Symbol',
  'Inverted Cross',
  '"Do You Sign My Paychecks?" Backpatch',
  '"I ♥ Myself"',
  'Double Cherry',
  '"Volunteer"',
  '"Solve Et Coagula" (Baphomet)',
]

export default patches

import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, TransitionGroup as _TransitionGroup, resolveComponent as _resolveComponent, createBlock as _createBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"
import _imports_0 from '../assets/icons/x-circle.svg'


const _withScopeId = n => (_pushScopeId("data-v-3390f722"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "modal-container" }
const _hoisted_2 = { class: "flex-column" }
const _hoisted_3 = ["onClick"]
const _hoisted_4 = ["onClick"]
const _hoisted_5 = ["onClick"]
const _hoisted_6 = { class: "in-progress-flex plain-text" }
const _hoisted_7 = ["onClick"]
const _hoisted_8 = {
  key: 1,
  class: "spacer"
}
const _hoisted_9 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Header = _resolveComponent("Header")!
  const _component_TrainNewSkill = _resolveComponent("TrainNewSkill")!
  const _component_Modal = _resolveComponent("Modal")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_TransitionGroup, null, {
        default: _withCtx(() => [
          (_ctx.skills.trained.length > 0)
            ? (_openBlock(), _createBlock(_component_Header, {
                title: "Trained Skills",
                subtitle: "+ 10",
                key: "trained"
              }, {
                default: _withCtx(() => [
                  _createVNode(_TransitionGroup, { tag: "ul" }, {
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.skills.trained, (skill) => {
                        return (_openBlock(), _createElementBlock("li", {
                          class: "plain-text",
                          key: skill
                        }, [
                          _createTextVNode(_toDisplayString(skill) + " ", 1),
                          _createVNode(_Transition, null, {
                            default: _withCtx(() => [
                              (_ctx.editMode)
                                ? (_openBlock(), _createElementBlock("img", {
                                    key: 0,
                                    onClick: ($event: any) => (_ctx.deleteSkill(skill, 'trained')),
                                    src: _imports_0,
                                    class: "button"
                                  }, null, 8, _hoisted_3))
                                : _createCommentVNode("", true)
                            ]),
                            _: 2
                          }, 1024)
                        ]))
                      }), 128))
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          (_ctx.skills.expert.length > 0)
            ? (_openBlock(), _createBlock(_component_Header, {
                title: "Expert Skills",
                subtitle: "+ 15",
                key: "expert"
              }, {
                default: _withCtx(() => [
                  _createVNode(_TransitionGroup, { tag: "ul" }, {
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.skills.expert, (skill) => {
                        return (_openBlock(), _createElementBlock("li", {
                          class: "plain-text",
                          key: skill
                        }, [
                          _createTextVNode(_toDisplayString(skill) + " ", 1),
                          _createVNode(_Transition, null, {
                            default: _withCtx(() => [
                              (_ctx.editMode)
                                ? (_openBlock(), _createElementBlock("img", {
                                    key: 0,
                                    onClick: ($event: any) => (_ctx.deleteSkill(skill, 'expert')),
                                    src: _imports_0,
                                    class: "button"
                                  }, null, 8, _hoisted_4))
                                : _createCommentVNode("", true)
                            ]),
                            _: 2
                          }, 1024)
                        ]))
                      }), 128))
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          (_ctx.skills.master.length > 0)
            ? (_openBlock(), _createBlock(_component_Header, {
                title: "Master Skills",
                subtitle: "+ 20",
                key: "master"
              }, {
                default: _withCtx(() => [
                  _createVNode(_TransitionGroup, { tag: "ul" }, {
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.skills.master, (skill) => {
                        return (_openBlock(), _createElementBlock("li", {
                          class: "plain-text",
                          key: skill
                        }, [
                          _createTextVNode(_toDisplayString(skill) + " ", 1),
                          _createVNode(_Transition, null, {
                            default: _withCtx(() => [
                              (_ctx.editMode)
                                ? (_openBlock(), _createElementBlock("img", {
                                    key: 0,
                                    onClick: ($event: any) => (_ctx.deleteSkill(skill, 'master')),
                                    src: _imports_0,
                                    class: "button"
                                  }, null, 8, _hoisted_5))
                                : _createCommentVNode("", true)
                            ]),
                            _: 2
                          }, 1024)
                        ]))
                      }), 128))
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          _createVNode(_Transition, {
            mode: "out-in",
            key: "in-progress-transition"
          }, {
            default: _withCtx(() => [
              (_ctx.skills.inProgress.skill)
                ? (_openBlock(), _createBlock(_component_Header, {
                    key: 0,
                    title: "Skill in Progress"
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("div", _hoisted_6, [
                        (_openBlock(), _createElementBlock(_Fragment, null, _renderList(5, (n) => {
                          return _createElementVNode("div", {
                            class: _normalizeClass(["check-box button", _ctx.checkboxClass(n)]),
                            onClick: ($event: any) => (_ctx.clickCheckbox(n))
                          }, null, 10, _hoisted_7)
                        }), 64)),
                        _createTextVNode(" " + _toDisplayString(_ctx.skills.inProgress.skill) + " (" + _toDisplayString(_ctx.skills.inProgress.level[0].toUpperCase()) + ") ", 1),
                        _createVNode(_Transition, { mode: "out-in" }, {
                          default: _withCtx(() => [
                            (_ctx.editMode)
                              ? (_openBlock(), _createElementBlock("img", {
                                  key: 0,
                                  onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.cancelInProgress && _ctx.cancelInProgress(...args))),
                                  src: _imports_0,
                                  class: "button"
                                }))
                              : (_openBlock(), _createElementBlock("div", _hoisted_8))
                          ]),
                          _: 1
                        })
                      ])
                    ]),
                    _: 1
                  }))
                : (_openBlock(), _createElementBlock("button", {
                    class: "new-skill-btn",
                    onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.newSkill && _ctx.newSkill(...args))),
                    disabled: _ctx.newSkillModal || _ctx.editMode,
                    key: "train-button"
                  }, " Train New Skill ", 8, _hoisted_9))
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _createVNode(_component_Modal, {
      visible: !_ctx.editMode && _ctx.newSkillModal,
      title: "Train New Skill",
      onClose: _ctx.closeNewSkillModal,
      color: "#ffe7bd"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_TrainNewSkill)
      ]),
      _: 1
    }, 8, ["visible", "onClose"])
  ]))
}

import { defineComponent } from 'vue'

export default defineComponent({
  props:{
    visible: Boolean,
    color: String,
    title: String,
    zIndex: {
      type: String,
      default: '100',
    }
  },
})

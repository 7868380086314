import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_StatValue = _resolveComponent("StatValue")!
  const _component_LabeledBox = _resolveComponent("LabeledBox")!

  return (_openBlock(), _createBlock(_component_LabeledBox, { label: "STATS" }, {
    default: _withCtx(() => [
      _createVNode(_component_StatValue, {
        name: "Strength",
        store: "stats.strength"
      }),
      _createVNode(_component_StatValue, {
        name: "Speed",
        store: "stats.speed"
      }),
      _createVNode(_component_StatValue, {
        name: "Intellect",
        store: "stats.intellect"
      }),
      _createVNode(_component_StatValue, {
        name: "Combat",
        store: "stats.combat"
      })
    ]),
    _: 1
  }))
}
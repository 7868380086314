
import { defineComponent, computed} from 'vue'
import { useStore } from 'vuex'

export default defineComponent({
  props: {
    name: String,
    store: {
      type: String,
      required: true
    }
  },
  setup(props){
    const store = useStore()
    const stat = computed(() => {
      const fullStoreLocation = `state.characterSheet.${props.store}`
      return fullStoreLocation.split(".").reduce((prev, current) => prev[current], store)
    })

    const save = (event) => {
      // we dont want to let ppl save an empty input,
      // it removes any indication the box is editable
      let value = event.target.value
      if (!value) {
        value = 0
      }

      store.commit(`set${props.name}`, value)
    }

    return {
      save,
      stat,
      name: props.name,
      editMode: computed(() => store.state.editMode)
    }
  }
})

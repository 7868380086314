
import { defineComponent, computed, ref } from 'vue'
import { useStore } from 'vuex'
import router from '@/router'
import Stats from '@/components/Stats.vue'
import Saves from '@/components/Saves.vue'
import PersonalDetails from "@/components/PersonalDetails.vue"
import Health from '@/components/Health.vue'
import Stress from '@/components/Stress.vue'
import StatValue from '@/components/StatValue.vue'
import TraumaResponse from '@/components/TraumaResponse.vue'
import LabeledBox from '@/components/LabeledBox.vue'
import Skills from '@/components/Skills.vue'
import Equipment from '@/components/Equipment.vue'
import HighScore from '@/components/HighScore.vue'
import Modal from "@/components/Modal.vue";
import StarField from "@/components/StarField.vue";

export default defineComponent({
  components: {
    StarField,
    Modal,
    LabeledBox,
    Stats,
    Saves,
    PersonalDetails,
    Health,
    Stress,
    StatValue,
    TraumaResponse,
    Skills,
    Equipment,
    HighScore,
  },
  async setup(){
    const store = useStore()

    if(!store.state.authToken){
      await router.push({name: 'landing'})
    } else {
      await store.dispatch('getSheet')
    }

    const showSignOutModal = ref(false)
    const signOut = () => {
      if (showSignOutModal.value){
        store.dispatch('signOut')
      } else {
        showSignOutModal.value = true
      }
    }

    const dying = ref(false)
    const showDeathModal = ref(false)
    const revive = () => {
      store.commit('revive')
      showDeathModal.value = false
    }
    const deathMaskOpacity = ref('.8')
    const showStarField = ref(false)
    const farewell = () => {
      dying.value = true;
      showDeathModal.value = false
      deathMaskOpacity.value = '1'
      setTimeout(()=> {
        showStarField.value = true
        deathMaskOpacity.value = '0'
        setTimeout(() => router.push({name: 'idWizard', params: {id: 0}}), 3000)
      }, 1000)

    }

    return{
      toggleEditMode: () => store.dispatch('toggleEditMode'),
      signOut,
      revive,
      farewell,
      dying,
      showSignOutModal,
      showDeathModal,
      deathMaskOpacity,
      showStarField,
      editMode: computed(() => store.state.editMode ),
      playerName: computed(() => store.state.playerName ?? ''),
      dead: computed(() => store.state.characterSheet.health.wounds === 0 && store.state.characterSheet.health.current === 0),
      name: computed(() => store.state.characterSheet.name),
    }
  }
})

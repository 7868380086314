import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderSlot as _renderSlot, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createBlock as _createBlock } from "vue"
import _imports_0 from '../assets/icons/x-square.svg'


const _hoisted_1 = { class: "modal-title" }
const _hoisted_2 = { class: "modal-content plain-text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock(_Transition, null, {
    default: _withCtx(() => [
      (_ctx.visible)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: "modal",
            style: _normalizeStyle({ backgroundColor: _ctx.color, zIndex: _ctx.zIndex })
          }, [
            _createElementVNode("img", {
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('close'))),
              src: _imports_0,
              class: "modal-close-button button"
            }),
            _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.title), 1),
            _createElementVNode("div", _hoisted_2, [
              _renderSlot(_ctx.$slots, "default")
            ])
          ], 4))
        : _createCommentVNode("", true)
    ]),
    _: 3
  }))
}
const skillTree = {
  trained: [
    {
      name: "Linguistics",
    },
    {
      name: "Zoology",
    },
    {
      name: "Botany",
    },
    {
      name: "Geology",
    },
    {
      name: "Industrial Equipment",
    },
    {
      name: "Jury-Rigging",
    },
    {
      name: "Chemistry",
    },
    {
      name: "Computers",
    },
    {
      name: "Zero-G",
    },
    {
      name: "Mathematics",
    },
    {
      name: "Art",
    },
    {
      name: "Archeology",
    },
    {
      name: "Theology",
    },
    {
      name: "Military Training",
    },
    {
      name: "Rimwise",
    },
    {
      name: "Athletics",
    },
  ],
  expert: [
    {
      name: "Psychology",
      prerequisites: [
        "Zoology",
        "Botany",
      ]
    },
    {
      name: "Pathology",
      prerequisites: [
        "Zoology",
        "Botany",
      ]
    },
    {
      name: "Field Medicine",
      prerequisites: [
        "Zoology",
        "Botany",
      ]
    },
    {
      name: "Ecology",
      prerequisites: [
        "Botany",
        "Geology"
      ]
    },
    {
      name: "Asteroid Mining",
      prerequisites: [
        "Geology",
        "Industrial Equipment"
      ]
    },
    {
      name: "Mechanical Repair",
      prerequisites: [
        "Industrial Equipment",
        "Jury-Rigging",
        "Chemistry",
        "Military Training",
      ]
    },
    {
      name: "Explosives",
      prerequisites: [
        "Jury-Rigging",
        "Chemistry",
        "Military Training",

      ]
    },
    {
      name: "Pharmacology",
      prerequisites: [
        "Chemistry",
      ]
    },
    {
      name: "Hacking",
      prerequisites: [
        "Computers",
      ]
    },
    {
      name: "Piloting",
      prerequisites: [
        "Zero-G",
      ]
    },
    {
      name: "Physics",
      prerequisites: [
        "Mathematics",
      ]
    },
    {
      name: "Mysticism",
      prerequisites: [
        "Art",
        "Archeology",
        "Theology",
      ]
    },
    {
      name: "Tactics",
      prerequisites: [
        "Military Training",
        "Botany", // lol, wut?
      ]
    },
    {
      name: "Wilderness Survival",
      prerequisites: [
        "Military Training",
        "Botany",
      ]
    },
    {
      name: "Firearms",
      prerequisites: [
        "Military Training",
        "Rimwise",
      ]
    },
    {
      name: "Hand-to-Hand Combat",
      prerequisites: [
        "Rimwise",
        "Athletics",
      ]
    },
  ],
  master: [
    {
      name: "Exobiology",
      prerequisites: [
        "Pathology",
      ]
    },
    {
      name: "Surgery",
      prerequisites: [
        "Pathology",
      ]
    },
    {
      name: "Planetology",
      prerequisites: [
        "Ecology",
        "Asteroid Mining",
      ]
    },
    {
      name: "Robotics",
      prerequisites: [
        "Mechanical Repair",
      ]
    },
    {
      name: "Engineering",
      prerequisites: [
        "Mechanical Repair",
      ]
    },
    {
      name: "Cybernetics",
      prerequisites: [
        "Mechanical Repair",
      ]
    },
    {
      name: "Artificial Intelligence",
      prerequisites: [
        "Hacking",
      ]
    },
    {
      name: "Hyperspace",
      prerequisites: [
        "Piloting",
        "Physics",
        "Mysticism",
      ]
    },
    {
      name: "Xenoesotericism",
      prerequisites: [
        "Mysticism",
      ]
    },
    {
      name: "Command",
      prerequisites: [
        "Tactics",
      ]
    },
    {
      name: "Gunnery",
      prerequisites: [
        "Firearms",
      ]
    },
  ]
}

export default skillTree

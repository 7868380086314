import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'
import CharacterSheet from '@/views/CharacterSheet.vue'
import LandingPage from '@/views/LandingPage.vue'
import NewCharacterWizard from '@/views/NewCharacterWizard.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/character-sheet',
    name: 'sheet',
    component: CharacterSheet,
  },
  {
    path: '/',
    name: 'landing',
    component: LandingPage,
  },
  {
    path: '/wizard',
    name: 'wizard',
    component: NewCharacterWizard,
  },
  {
    path: '/wizard/:id',
    name: 'idWizard',
    component: NewCharacterWizard,
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router

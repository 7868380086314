
import { defineComponent, computed} from 'vue'
import { useStore } from 'vuex'

export default defineComponent({
  setup(){
    const store = useStore()
    const editMode = computed(() => store.state.editMode)

    const breakArmor = () => {
      if(!editMode.value){
        store.commit('breakArmor')
        store.dispatch('saveSheet')
      }
    }

    const saveChange = (event) => {
      let value = Number(event.target.value)
      store.commit('setArmor', value)
    }

    return {
      breakArmor,
      saveChange,
      armor: computed(() => store.state.characterSheet.armor),
      editMode,
    }
  }
})


import { defineComponent, computed, ref } from 'vue'
import { useStore } from 'vuex'
import Header from '@/components/Header.vue'
import Modal from '@/components/Modal.vue'
import TrainNewSkill from '@/components/TrainNewSkill.vue'

export default defineComponent({
  components:{
    Header,
    Modal,
    TrainNewSkill
  },
  setup(){
    const store = useStore()
    const trainingCount = computed(() => store.state.characterSheet.skills.inProgress.training)
    const editMode = computed(() => store.state.editMode)

    let newSkillModal = ref(false)
    let newSkillUnsubscribe = ()=>{}
    const newSkill = (): void => {
      if (!editMode.value && !newSkillModal.value){
        newSkillModal.value = true
        newSkillUnsubscribe = store.subscribe((mutation, state) => {
          if (mutation.type === 'trainSkill'){
            closeNewSkillModal()
          }
        })
      }
    }

    const closeNewSkillModal = () => {
      newSkillModal.value = false
      newSkillUnsubscribe()
    }

    const clickCheckbox = (number: number): void => {
      if (number > trainingCount.value && !editMode.value) {
        store.commit('incrementSkillTraining')
        store.dispatch('saveSheet')
      }
      if (number <= trainingCount.value && editMode.value){
        store.commit('decrementSkillTraining')
      }
    }

    const deleteSkill = (skill: string, level: string): void => {
      store.commit('deleteSkill', {skill,level})
    }

    const checkboxClass = (index: number): string => {
      let classes = ''
      if (trainingCount.value >= index) {
        classes += ' filled '
        if (!editMode.value){
          classes += ' disabled '
        }
      }
      if (editMode.value) {
        classes += ' edit-mode '
        if (trainingCount.value < index){
          classes += ' disabled '
        }
      }
      return classes
    }

    const cancelInProgress = () => {
      store.commit('cancelSkillTraining')
    }

    return {
      clickCheckbox,
      deleteSkill,
      checkboxClass,
      cancelInProgress,
      newSkill,
      closeNewSkillModal,
      skills: computed(() => store.state.characterSheet.skills),
      editMode,
      newSkillModal,
    }
  }
})


import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    label: {
      type: String,
      required: true,
    },
    options: {
      type: Array,
      required: true,
    },
    color: String,
    hideLabel: {
      type: Boolean
    }
  },
  emits: [
    'change'
  ]
})

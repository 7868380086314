
import { defineComponent, computed } from 'vue'
import { useStore } from 'vuex'

export default defineComponent({
  emits: [
    'viewDetails',
    'new',
  ],
  setup(props, { emit }){
    const store = useStore()
    const editMode = computed(() => store.state.editMode )
    const conditions = computed(() => store.state.characterSheet.conditions )

    const determineGradient = (index) : string => {
      switch (conditions.value[index].treatment) {
        case 0:
          return 'gradient-0'
        case 1:
          return 'gradient-1'
        case 2:
          return 'gradient-2'
        default:
          return '' // returning nothing makes it easier to recognize if something has gone wrong
      }
    }

    const click = (index: number) => {
      if (editMode.value) {
        store.commit('undoTreatment', index)
      } else {
        emit('viewDetails', index)
      }
    }

    return {
      editMode,
      conditions,
      determineGradient,
      click,
    }
  }
})

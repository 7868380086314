
import {ref, onBeforeMount, Ref} from 'vue'
import StarField from '@/components/StarField.vue'
import defaultSheet from '@/assets/json/default-sheet'
import LabeledBox from "@/components/LabeledBox.vue"
import Classes from '@/enums/classes'
import DropDown from "@/components/DropDown.vue"
import skillsTree from "@/assets/json/skills-tree"
import { useStore } from 'vuex'
import {useRouter} from "vue-router"
import NextIcon from "@/assets/icons/next.svg"
import trinkets from '@/assets/json/trinkets'
import patches from '@/assets/json/patches'
import loadouts from '@/assets/json/loadouts'
import EquipmentTypes from "@/enums/equiptment-types";

export default {
  components: {DropDown, StarField, LabeledBox },
  setup(){
    const store = useStore()
    const router = useRouter()
    if(!store.state.authToken){
      router.push({name: 'landing'})
    }

    const character = ref(JSON.parse(JSON.stringify(defaultSheet)))

    const step = ref(1)
    const availableTrainedSkills = ref([])
    const availableExpertSkills = ref([])
    const scientistAvailableMasterSkills = ref([])
    const scientistSelectedMasterSkill: Ref<undefined | {name: string, prerequisites: [string]}> = ref(undefined)
    const scientistSelectedExpertSkill: Ref<string> = ref(undefined)
    const scientistSelectedTrainedSkill: Ref<string> = ref(undefined)
    const next = () => {
      switch (step.value){
        case 4:
          // Extra step for Androids and Scientists to pick stat boosts
          switch (character.value.class) {
            case Classes.ANDROID:
              step.value = 5
              break
            case Classes.SCIENTIST:
              step.value = 6
              break
            default:
              step.value = 7
          }
          break
        case 5:
          step.value = 7
          break
        case 7:
          availableTrainedSkills.value = skillsTree.trained.filter(skill => !character.value.skills.trained.includes(skill.name)).map(object => object.name)
          // Skill selection
          switch (character.value.class) {
            //Skill tree information duplicated here
            case Classes.MARINE:
              availableExpertSkills.value = ['Tactics', 'Wilderness Survival', 'Firearms', 'Hand-to-Hand Combat']
              step.value = 8
              break
            case Classes.ANDROID:
              availableExpertSkills.value = ['Hacking', 'Physics']
              step.value = 8
              break
            case Classes.SCIENTIST:
              scientistAvailableMasterSkills.value = skillsTree.master.filter(skill => {
                return skill.name !== 'Command'
              })
              step.value = 9
              break
            case Classes.TEAMSTER:
              //duplicate code alert - make sure you change this in both places in this file if it changes
              availableExpertSkills.value = ['Mechanical Repair', 'Asteroid Mining', 'Piloting']
              step.value = 10
              break
          }
          break
        case 8:
        case 10:
          if (selectedTrainedSkills.value.length > 0){
            character.value.skills.trained.push(...selectedTrainedSkills.value)
          }
          if(selectedExpertSkill.value) {
            character.value.skills.expert.push(selectedExpertSkill.value)
          }
          step.value = 11
          break
        case 9:
          character.value.skills.trained.push(scientistSelectedTrainedSkill.value)
          character.value.skills.expert.push(scientistSelectedExpertSkill.value)
          character.value.skills.master.push(scientistSelectedMasterSkill.value.name)
          step.value = 11
          break
        case 11:
          finish()
          break
        default:
          step.value++
          break
      }
    }

    const selectClass = (selection) => {
      character.value.class = selection
      switch (selection) {
        case Classes.MARINE:
          character.value.stats.combat += 10
          character.value.saves.body += 10
          character.value.saves.fear += 20
          character.value.health.maxWounds = 3
          character.value.health.wounds = 3
          character.value.stress.traumaResponse = 'Whenever you panic, every nearby friendly player must make a fear save.'
          character.value.skills.trained.push('Military Training', 'Athletics')
          break
        case Classes.ANDROID:
          character.value.stats.intellect += 20
          character.value.saves.fear += 60
          character.value.health.maxWounds = 3
          character.value.health.wounds = 3
          character.value.stress.traumaResponse = 'Fear saves made by nearby friendly players are at disadvantage.'
          character.value.skills.trained.push('Linguistics','Computers','Mathematics')
          break
        case Classes.SCIENTIST:
          character.value.stats.intellect += 10
          character.value.saves.sanity += 30
          character.value.stress.traumaResponse = 'Whenever you fail a sanity save, all nearby friendly players gain 1 stress.'
          break
        case Classes.TEAMSTER:
          character.value.stats.intellect += 5
          character.value.stats.combat += 5
          character.value.stats.strength += 5
          character.value.stats.speed += 5
          character.value.saves.sanity += 10
          character.value.saves.fear += 10
          character.value.saves.body += 10
          character.value.stress.traumaResponse = 'Once per session, you may take advantage on a panic check.'
          character.value.skills.trained.push('Industrial Equipment', 'Zero-G')
      }
      next()
    }

    const selectedTrainedSkills = ref([])
    const selectedExpertSkill = ref('')
    const selectTrainedSkill = (skill: string) => {
      selectedExpertSkill.value = ''
      const index = selectedTrainedSkills.value.findIndex(selectedSkill => selectedSkill === skill)
      if (index === -1 ){
        selectedTrainedSkills.value.push(skill)
        if (selectedTrainedSkills.value.length > 2) {
          selectedTrainedSkills.value.splice(0, 1)
        }
      } else {
        selectedTrainedSkills.value.splice(index, 1)
      }
    }
    const selectExpertSkill = (skill) => {
      if (selectedExpertSkill.value === skill) {
        selectedExpertSkill.value = ''
      } else {
        selectedExpertSkill.value = skill
      }
      selectedTrainedSkills.value = []
    }
    const selectTrainedSkillTeamster = (skill: string) => {
      selectedTrainedSkills.value[0] = skill
      //duplicate code alert - make sure you change this in both places in this file if it changes
      availableExpertSkills.value = ['Mechanical Repair', 'Asteroid Mining', 'Piloting']
      if (!availableExpertSkills.value.includes(selectedExpertSkill.value)){
        selectedExpertSkill.value = ''
      }
      const newExpertSkills = skillsTree.expert.filter(expertSkill => expertSkill.prerequisites.includes(skill)).map(expertSkill => expertSkill.name)
      availableExpertSkills.value.push(...newExpertSkills)
    }
    const selectExpertSkillTeamster = (skill) => {
      selectedExpertSkill.value = skill
    }

    const scientistSelectExpertSkill = (selectedSkill: string) => {
      scientistSelectedExpertSkill.value = selectedSkill
      availableTrainedSkills.value = skillsTree.expert.find(treeSkill => treeSkill.name = selectedSkill).prerequisites
    }

    const loading = ref(false)
    const finish = async () => {
      loading.value = true
      character.value.equipment = loadouts[character.value.class][Math.floor(Math.random() * loadouts[character.value.class].length)]
      character.value.armor = character.value.equipment.wearing.armor
      carryCapacity()
      if (router.currentRoute.value.params.id === '0'){
        await store.dispatch('overwriteSheet', { id: 0, sheet: character.value })
      } else {
        await store.dispatch('createSheet', character.value)
      }
      router.push({name: 'sheet'})
    }

    const validateStats = () => {
      for (const stat in character.value.stats) {
        if (character.value.stats[stat] > 99) {
          character.value.stats[stat] = 99
        }
        if (character.value.stats[stat] < 1) {
          character.value.stats[stat] = 1
        }
      }
    }

    const carryCapacity = () => {
      while (character.value.equipment.equipped.length < (Math.ceil((character.value.stats.strength) / 10) * 2)){
        character.value.equipment.equipped.push({
          type: EquipmentTypes.EMPTY,
        })
      }
    }

    const validateSaves = () => {
      for (const save in character.value.saves) {
        if (character.value.saves[save] > 99) {
          character.value.saves[save] = 99
        }
        if (character.value.saves[save] < 1) {
          character.value.saves[save] = 1
        }
      }
    }

    const validateHealth = () => {
      if (character.value.health.maxHealth > 20) {
        character.value.health.maxHealth = 20
      }
      if (character.value.health.maxHealth < 1) {
        character.value.health.maxHealth = 1
      }
      character.value.health.current = character.value.health.maxHealth
    }

    onBeforeMount(() => {
      // This ensures state is not preserved between wizards
      character.value = JSON.parse(JSON.stringify(defaultSheet))
      const rollD10 = () => Math.floor(Math.random() * 10) + 1
      character.value.stats.intellect = rollD10() + rollD10() + 20
      character.value.stats.combat = rollD10() + rollD10() + 20
      character.value.stats.speed = rollD10() + rollD10() + 20
      character.value.stats.strength = rollD10() + rollD10() + 20
      character.value.saves.sanity = rollD10() + rollD10() + 10
      character.value.saves.body = rollD10() + rollD10() + 10
      character.value.saves.fear = rollD10() + rollD10() + 10
      character.value.health.maxHealth = rollD10() + 10
      character.value.health.current = character.value.health.maxHealth
      character.value.credits = (rollD10() + rollD10()) * 10
      character.value.patch = patches[Math.floor(Math.random() * patches.length)]
      character.value.trinket = trinkets[Math.floor(Math.random() * trinkets.length)]
    })

    return {
      character,
      step,
      next,
      Classes,
      selectClass,
      selectedTrainedSkills,
      selectTrainedSkill,
      selectTrainedSkillTeamster,
      selectedExpertSkill,
      selectExpertSkill,
      selectExpertSkillTeamster,
      availableTrainedSkills,
      availableExpertSkills,
      scientistAvailableMasterSkills,
      scientistSelectedMasterSkill,
      scientistSelectedExpertSkill,
      scientistSelectedTrainedSkill,
      scientistSelectExpertSkill,
      loading,
      NextIcon,
      validateStats,
      validateSaves,
      validateHealth,
    }
  }
}


import {defineComponent} from 'vue'
import StatValue from '@/components/StatValue.vue'
import LabeledBox from '@/components/LabeledBox.vue'

export default defineComponent({
  components: {
      LabeledBox,
      StatValue
    }
  })

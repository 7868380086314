
import { defineComponent, computed } from 'vue'
import { useStore } from 'vuex'

export default defineComponent({
  setup(){
    const store = useStore()
    const editMode = computed(() => store.state.editMode)

    const currentUp = () : void => {
      if (editMode.value || store.state.characterSheet.stress.current === 20) {
        return
      }
      store.commit('incrementCurrentStress')
      store.dispatch('saveSheet')
    }

    const currentDown = () : void => {
      if (
        editMode.value ||
        store.state.characterSheet.stress.current === store.state.characterSheet.stress.minimum
      ) {
        return
      }
      store.commit('decrementCurrentStress')
      store.dispatch('saveSheet')
    }

    const setMin = (event:any) => {
      // we dont want to let ppl save an empty input,
      // it removes any indication the box is editable
      let value = event.target.value
      if (!value) {
        value = 0
      }

      store.commit("setMinStress", value)
    }

    const downClass = computed(() => {
      if (
        editMode.value ||
        store.state.characterSheet.stress.current === store.state.characterSheet.stress.minimum
      ){
        return 'disabled'
      } else {
        return ''
      }
    })

    const upClass = computed(() => {
      if (
        editMode.value ||
        store.state.characterSheet.stress.current === 20
      ){
        return 'disabled'
      } else {
        return ''
      }
    })

    return {
      currentUp,
      currentDown,
      setMin,
      downClass,
      upClass,
      current: computed(() => store.state.characterSheet.stress.current),
      minimum: computed(() => store.state.characterSheet.stress.minimum),
      editMode,
    }
  }
})

const trinkets = [
  'Manual: PANIC: Harbinger of Catastrophe',
  'Antique Company Script (Asteroid Mine)',
  'Manual: SURVIVAL: Eat Soup With a Knife',
  'Dessicated Husk Doll',
  'Alien Pressed Flower (common)',
  'Necklace of Shell Casings',
  'Corroded Android Logic Core',
  'Pamphlet: Signs of Parasitical Infection',
  'Manual: Treat Your Rifle Like A Lady',
  'Bone Knife',
  'Calendar: Alien Pin-Up Art',
  'Rejected Application (Colony Ship)',
  'Holographic Serpentine Dancer',
  'Snake Whiskey',
  'Medical Container, Purple Powder',
  'Pills: Male Enhancement, Shoddy',
  'Casino Playing Cards',
  'Lagomorph Foot',
  'Moonstone Ring',
  'Manual: Mining Safety and You',
  'Pamphlet: Against Human Simulacrum',
  'Animal Skull, 3 Eyes, Curled Horns',
  'Bartender\'s Certification (Expired)',
  'Bunraku Puppet',
  'Prospecting Mug, Dented',
  'Eerie Mask',
  'Ultrablack Marble',
  'Ivory Dice',
  'Tarot Cards, Worn, Pyrite Gilded Edges',
  'Bag of Assorted Teeth',
  'Ashes (A Relative)',
  'DNR Beacon Necklace',
  'Cigarettes (Grinning Skull)',
  'Pills: Areca Nut',
  'Pendant: Shell Fragments Suspended in Plastic',
  'Pamphlet: Zen and the Art of Cargo Arrangement',
  'Pair of Shot Glasses (Spent Shotgun Shells)',
  'Key (Childhood Home)',
  'Dog Tags (Heirloom)',
  'Token: "Is Your Morale Improving?"',
  'Pamphlet: Teh Relic of Flesh',
  'Pamphlet: The Indifferent Stars',
  'Calendar: Military Battles',
  'Manual: Rich Captain, Poor Captain',
  'Campaign Poster (Home Planet)',
  'Preserved Insectile Aberration',
  'Titanium Toothpick',
  'Gloves, Leather (Xenomorph Hide)',
  'Smut (Seditious): The Captain, Ordered',
  'Towel, Slightly Frayed',
  'Brass Knuckles',
  'Fuzzy Handcuffs',
  'Journal of Grudges',
  'Stylized Cigarette Case',
  'Ball of Assorted Gauge Wire',
  'Spanner',
  'Switchblade, Ornamental',
  'Powered Xenomorph Horn',
  'Bonsai Tree, Potted',
  'Golf Club (Putter)',
  'Trilobite Fossil',
  'Pamphlet: A Lover In Every Port',
  'Patched Overalls, Personalized',
  'Fleshy Thing Sealed in a Murky Jar',
  'Spiked Bracelet',
  'Harmonica',
  'Pictorial Pornography, Dogeared, Well-thumbed',
  'Coffee Cup, Chipped, reads: HAPPINESS IS MANDATORY',
  'Manual: Moonshining With Gun Oil & Fuel',
  'Miniature Chess Set, Bone, Pieces Missing',
  'Gyroscope, Bent, Tin',
  'Faded Green Poker Chip',
  'Ukulele',
  'Spray Paint',
  'Wanted Poster, Weathered',
  'Locket, Hair Braid',
  'Sculpture of a Rat (Gold)',
  'Blanket, Fire Retardant',
  'Hooded Parka, Fleece-Lined',
  'BB Gun',
  'Flint Hatchet',
  'Pendant: Two Astronauts form a Skull',
  'Rubik\'s Cube',
  'Stress Ball, reads: Zero Stress in Zero G',
  'Sputnik Pin',
  'Ushanka',
  'Trucker Cap, Mesh, Grey Alien Logo',
  'Menthol Balm',
  'Pith Helmet',
  '10m x 10m Tarp',
  'I Ching, Missing Sticks',
  'Kukri',
  'Trench Shovel',
  'Shiv, Sharpened Butter Knife',
  'Taxidermied Cat',
  'Pamphlet: Interpreting Sheep Dreams',
  'Faded Photograph, A Windswept Heath',
  'Opera Glasses',
  'Pamphlet: Android Overlords',
  'Interstellar Compass, Always Points to Homeworld',
]

export default trinkets

import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_StatValue = _resolveComponent("StatValue")!
  const _component_labeled_box = _resolveComponent("labeled-box")!

  return (_openBlock(), _createBlock(_component_labeled_box, { label: "SAVES" }, {
    default: _withCtx(() => [
      _createVNode(_component_StatValue, {
        name: "Sanity",
        store: "saves.sanity"
      }),
      _createVNode(_component_StatValue, {
        name: "Fear",
        store: "saves.fear"
      }),
      _createVNode(_component_StatValue, {
        name: "Body",
        store: "saves.body"
      })
    ]),
    _: 1
  }))
}
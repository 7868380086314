
import { defineComponent, computed} from 'vue'
import { useStore } from 'vuex'
import EquipmentTypes from '@/enums/equiptment-types'
import Modal from '@/components/Modal.vue'

export default defineComponent({
  props: {
    index: {
      type: Number,
      required: true,
    },
  },
  components: {
    Modal
  },
  emits: [
    "itemModal",
    "emptyModal",
    "dropModal",
  ],
  setup(props, { emit }){
    const store = useStore()
    const equipped = computed<[]>(()=> store.state.characterSheet.equipment.equipped )
    const item = computed(() => store.state.characterSheet.equipment.equipped[props.index as number])
    const editMode = computed<boolean>(()=> store.state.editMode )

    const eventEmitter = () => {
      if (editMode.value){
        return
      }

      if (item.value.type === EquipmentTypes.EMPTY) {
        emit('emptyModal')
      } else {
        emit('itemModal')
      }
    }

    const empty = () : boolean => {
      return item.value.type === EquipmentTypes.EMPTY
    }

    const drop = (event: Event) => {
      event.stopPropagation()
      emit('dropModal')
    }

    const use = (event: Event) => {
      if (
        !editMode.value
        && item.value.uses > 0
        && item.value.type !== EquipmentTypes.AMMO
      )
      {
        event.stopPropagation()
        store.commit('useItem', props.index)
        store.dispatch('saveSheet')
      }
    }

    const reload = (event: Event) => {
      // @ts-ignore
      const ammoIndex = equipped.value.findIndex(item => item.type === EquipmentTypes.AMMO && item.uses > 0)
      if (ammoIndex !== -1 && item.value.maxUses !== item.value.uses) {
        event.stopPropagation()
        if (!editMode.value){
          store.commit('reload', props.index)
          store.dispatch('saveSheet')
        }
      }
    }

    const manualUsesChange = (event) => {
      let number = Number(event.target.value)
      store.commit('setUses', { index: props.index, uses: number })
    }

    //TODO: Reload should also just get its own function
    const buttonClass = (reload: boolean = false) : string => {
      // @ts-ignore
      const ammoIndex = equipped.value.findIndex(item => item.type === EquipmentTypes.AMMO && item.uses > 0)
      if ( editMode.value ||
        item.value.type == EquipmentTypes.AMMO ||
        (!reload && item.value.uses  === 0) ||
        (reload && ammoIndex === -1) ||
        (reload && item.value.maxUses === item.value.uses)
      ) {
        return 'icon-inactive'
      } else {
        return 'icon-active'
      }
    }

    const boltFill = () : string => {
      if ( editMode.value ||
        item.value.uses === 0
      ) {
        return 'none'
      } else if ( item.value.type === EquipmentTypes.AMMO ) {
        return 'black'
      } else {
        return '#4b8c4b'
      }
    }

    const svgThickness = () : string => {
      if ( editMode.value ||
        item.value.type == EquipmentTypes.AMMO ) {
        return '2'
      } else {
        return '3'
      }
    }

    return {
      equipped,
      editMode,
      EquipmentTypes,
      eventEmitter,
      drop,
      use,
      reload,
      manualUsesChange,
      buttonClass,
      svgThickness,
      boltFill,
      empty,
    }
  }
})


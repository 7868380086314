import EquipmentTypes from "@/enums/equiptment-types"
import Classes from "@/enums/classes"

const loadouts = {
  [Classes.MARINE]: [
    {
      equipped: [
        {
          name: 'Combat Knife',
          description: 'Range: Adjacent \n\n' +
            'Damage: 1d5 [+]\n\n' +
            'Wound: Bleeding [+]',
          type: EquipmentTypes.BASIC,
        },
        {
          name: 'Stimpack',
          description: 'Cures cyrosickness. Restores 1d10 Health and grants Advantage to Strength and Combat for 2d10 minutes.' +
            'There is a danger of addiction and/or overdose if used frequently.',
          type: EquipmentTypes.USES,
          uses: 1,
          maxUses: 5,
        },
      ],
      locker: [],
      wearing: {
        name: 'Tank Top and Camo Pants',
        type: EquipmentTypes.ARMOR,
        description: 'A badass needs no armor.',
        armor: 1,
        broken: false,
      },
    },
    {
      equipped: [
        {
          name: 'Flamethrower',
          description: 'Range: Close \n\n' +
            'Damage: 1 Wound \n\n' +
            'Wound: Fire/Explosives [+] \n\n' +
            'Special: If liquid fuel tank is destroyed, 1 Wound to all Close',
          uses: 4,
          maxUses: 4,
          type: EquipmentTypes.GUN,
        },
        {
          name: 'Boarding Axe',
          description: 'Range: Adjacent \n\n ' +
            'Damage: 2d10 [+] \n\n' +
            'Wound: Gore[+]',
          type: EquipmentTypes.BASIC,
        },
      ],
      locker: [],
      wearing: {
        name: 'Advanced Battle Dress',
        type: EquipmentTypes.ARMOR,
        armor: 10,
        broken: false,
        description: 'Heavy armor for marines deployed in high combat offworld engagements. \n\n' +
          'Disadvantage on speed checks. \n\n' +
          'Advantage on strength checks. \n\n' +
          '1 hour O2 supply. \n\n' +
          'Ignores the first Wound you suffer \n\n' +
          'Includes: short-range comms, body cam, headlamp, HUD, exoskeleton weave, radiation shielding.'
      },
    },
    {
      equipped: [
        {
          name: 'Combat Shotgun',
          description: 'Range: Close \n\n' +
            'Damage: 1 Wound \n\n' +
            'Wound: Gunshot \n\n' +
            'Special: 1d10 DMG at Long Range',
          type: EquipmentTypes.GUN,
          uses: 4,
          maxUses: 4,
        },
        {
          name: 'MoHab Unit',
          description: 'Tent, canteen, stove, rucksack, compass, and sleeping bag.',
          type: EquipmentTypes.BASIC,
        },
      ],
      locker: [],
      wearing: {
        name: 'Standard Battle Dress',
        type: EquipmentTypes.ARMOR,
        armor: 7,
        broken: false,
        description: 'Lightly-plated armor worn by most marines. \n\n' +
          'Includes: short-range comms.',
      },
    },
    {
      equipped: [
        {
          name: 'Pulse Rifle',
          description: 'Range: Long \n\n' +
            'Damage: 3d10 \n\n' +
            'Wound: Gunshot \n\n' +
            'Special: Pump-action grenade launcher holds x3 frag grenades.',
          uses: 5,
          maxUses: 5,
          type: EquipmentTypes.GUN,
        },
        {
          name: 'Infrared Goggles',
          description: 'Allows the wearer to see heat signatures, sometimes up to several hours old.',
          type: EquipmentTypes.BASIC,
        },
        {
          name: 'Ammo',
          description: 'Extra rounds. Is it enough though?',
          type: EquipmentTypes.AMMO,
          uses: 2,
          maxUses: 3, // good idea?
        },
      ],
      locker: [],
      wearing: {
        name: 'Standard Battle Dress',
        type: EquipmentTypes.ARMOR,
        armor: 7,
        broken: false,
        description: 'Lightly-plated armor worn by most marines. \n\n' +
          'Includes: short-range comms.',
      },
    },
    {
      equipped: [
        {
          name: 'Smart Rifle',
          description: 'Range: Long \n\n' +
            'Damage: 1d10x10 \n\n' +
            'Wound: Gunshot [+] \n\n' +
            'Special: Two-handed. Heavy. If not braced/prone when firing, Body Save or be knocked down.',
          uses: 3,
          maxUses: 3,
          type: EquipmentTypes.GUN,
        },
        {
          name: 'Binoculars',
          description: '20x magnification',
          type: EquipmentTypes.BASIC,
        },
        {
          name: 'Personal Locator',
          description: 'Allows crewmembers at a control center or on the bridge of a ship to track the location of the wearer.',
          type: EquipmentTypes.BASIC,
        },
      ],
      locker: [],
      wearing: {
        name: 'Standard Battle Dress',
        type: EquipmentTypes.ARMOR,
        armor: 7,
        broken: false,
        description: 'Lightly-plated armor worn by most marines. \n\n' +
          'Includes: short-range comms.',
      },
    },
    {
      equipped: [
        {
          name: 'SMG',
          description: 'Range: Long \n\n' +
            'Damage: 2d10 \n\n' +
            'Wound: Gunshot \n\n' +
            'Special: Can be held one-handed.',
          uses: 4,
          maxUses: 4,
          type: EquipmentTypes.GUN,
        },
        {
          name: 'MRE',
          description: '"Meals, Ready-to-Eat." Self-contained, individual field rations in lightweight packaging. Each one ' +
            'has sufficient sustenance for a single person for a day. Does not include water.',
          type: EquipmentTypes.USES,
          uses: 7,
          maxUses: 7,
        },
        {
          name: 'Ammo',
          description: 'Extra rounds. Is it enough though?',
          type: EquipmentTypes.AMMO,
          uses: 2,
          maxUses: 3, // good idea?
        },
      ],
      locker: [],
      wearing: {
        name: 'Standard Battle Dress',
        type: EquipmentTypes.ARMOR,
        armor: 7,
        broken: false,
        description: 'Lightly-plated armor worn by most marines. \n\n' +
          'Includes: short-range comms.',
      },
    },
    {
      equipped: [
        {
          name: 'Combat Shotgun',
          description: 'Range: Close \n\n' +
            'Damage: 1 Wound \n\n' +
            'Wound: Gunshot \n\n' +
            'Special: 1d10 DMG at Long Range',
          type: EquipmentTypes.GUN,
          uses: 4,
          maxUses: 4,
        },
        {
          name: 'Leash',
          description: 'Hand loop on one end. Collar on the other.',
          type: EquipmentTypes.BASIC,
        },
        {
          name: 'Tennis Ball',
          description: 'Round and yellow.',
          type: EquipmentTypes.BASIC,
        },
        {
          name: 'Dog',
          description: 'Who saved whom? \n\n' +
            '1 Wound. 10 Health. \n\n' +
            'Instinct: 54 \n\n' +
            '[+] on Rest Saves \n\n' +
            '1 Stress whenever they take Damage. If killed, Minimum Stress +1 and Panic Check.',
          type: EquipmentTypes.BASIC,
        },
      ],
      locker: [],
      wearing: {
        name: 'Fatigues',
        type: EquipmentTypes.ARMOR,
        armor: 2,
        broken: false,
        description: 'What environment is this camo even meant for?',
      },
    },
    {
      equipped: [
        {
          name: 'Revolver',
          description: 'Range: Close \n\n' +
            'Damage: 1d10 [+] \n\n' +
            'Wound: Gunshot',
          uses: 6,
          maxUses: 6,
          type: EquipmentTypes.GUN,
        },
        {
          name: 'Frag Grenade',
          description: 'Range: Long \n\n' +
            'Damage: 1d5-1 Wounds \n\n' +
            'Wound: Fire/Explosives \n\n' +
            'Special: All Close must Body Save to avoid.',
          type: EquipmentTypes.BASIC,
        },
        {
          name: 'Ammo',
          description: 'Extra rounds. Is it enough though?',
          type: EquipmentTypes.AMMO,
          uses: 1,
          maxUses: 3, // good idea?
        },
      ],
      locker: [],
      wearing: {
        name: 'Fatigues',
        type: EquipmentTypes.ARMOR,
        armor: 2,
        broken: false,
        description: 'What environment is this camo even meant for?',
      },
    },
    {
      equipped: [
        {
          name: 'Revolver',
          description: 'Range: Close \n\n' +
            'Damage: 1d10 [+] \n\n' +
            'Wound: Gunshot',
          uses: 1,
          maxUses: 6,
          type: EquipmentTypes.GUN,
        },
        {
          name: 'Challenge Coin',
          description: 'Never without it. This baby earned you a lot of free drinks at the Academy.',
          type: EquipmentTypes.BASIC,
        },
      ],
      locker: [],
      wearing: {
        name: 'Dress Uniform',
        type: EquipmentTypes.ARMOR,
        armor: 1,
        broken: false,
        description: 'Well aren\'t you fancy.',
      },
    },
    {
      equipped: [
        {
          name: 'General-Purpose Machine Gun',
          description: 'Range: Long \n\n' +
            'Damage: 4d10 [+] \n\n' +
            'Wound: Gunshot [+] \n\n' +
            'Special: Two-handed. Heavy. Barrel can be maneuvered to fire around corners.',
          uses: 5,
          maxUses: 5,
          type: EquipmentTypes.GUN,
        },
        {
          name: 'Heads-Up Display (HUD)',
          description: 'Often worn by marines, the HUD allows the wearer to see through the body cams of others in their unit, and ' +
            'can connect to any smart-link upgraded weapon.',
          type: EquipmentTypes.BASIC,
        },
      ],
      locker: [],
      wearing: {
        name: 'Advanced Battle Dress',
        type: EquipmentTypes.ARMOR,
        armor: 10,
        broken: false,
        description: 'Heavy armor for marines deployed in high combat offworld engagements. \n\n' +
          'Disadvantage on speed checks. \n\n' +
          'Advantage on strength checks. \n\n' +
          '1 hour O2 supply. \n\n' +
          'Ignores the first Wound you suffer \n\n' +
          'Includes: short-range comms, body cam, headlamp, HUD, exoskeleton weave, radiation shielding.'
      },
    },
  ],
  [Classes.ANDROID]: [
    {
      equipped: [
        {
          name: 'Smart Rifle',
          description: 'Range: Long \n\n' +
            'Damage: 1d10x10 \n\n' +
            'Wound: Gunshot [+] \n\n' +
            'Special: Two-handed. Heavy. If not braced/prone when firing, Body Save or be knocked down.',
          uses: 3,
          maxUses: 3,
          type: EquipmentTypes.GUN,
        },
        {
          name: 'Infrared Goggles',
          description: 'Allows the wearer to see heat signatures, sometimes up to several hours old.',
          type: EquipmentTypes.BASIC,
        },
        {
          name: 'Mylar Blanket',
          description: 'Lightweight blanket made of heat-reflective material. Often used for thermal control of patients ' +
            'suffering from extreme cold or other trauma.',
          type: EquipmentTypes.BASIC,
        },
        {
          name: 'Ammo',
          description: 'Extra rounds. Is it enough though?',
          type: EquipmentTypes.AMMO,
          uses: 1,
          maxUses: 3, // good idea?
        },
      ],
      locker: [],
      wearing: {
        name: 'Vaccsuit',
        type: EquipmentTypes.ARMOR,
        armor: 3,
        broken: false,
        description: 'Worn while operating in outer space. \n\n' +
          'Disadvantage on speed checks. \n\n' +
          '12 hours O2 supply. \n\n' +
          'Decompression within 1d5 rounds when punctured. \n\n' +
          'Includes: short-range comms, headlamp, and radiation shielding.',
      },
    },
    {
      equipped: [
        {
          name: 'Revolver',
          description: 'Range: Close \n\n' +
            'Damage: 1d10 [+] \n\n' +
            'Wound: Gunshot',
          uses: 6,
          maxUses: 6,
          type: EquipmentTypes.GUN,
        },
        {
          name: 'Long-Range Comms',
          description: 'Rucksack-sized communication device for use in surface-to-ship communication.',
          type: EquipmentTypes.BASIC,
        },
        {
          name: 'Ammo',
          description: 'Extra rounds. Is it enough though?',
          type: EquipmentTypes.AMMO,
          uses: 1,
          maxUses: 3, // good idea?
        },
      ],
      locker: [],
      wearing: {
        name: 'Vaccsuit',
        type: EquipmentTypes.ARMOR,
        armor: 3,
        broken: false,
        description: 'Worn while operating in outer space. \n\n' +
          'Disadvantage on speed checks. \n\n' +
          '12 hours O2 supply. \n\n' +
          'Decompression within 1d5 rounds when punctured. \n\n' +
          'Includes: short-range comms, headlamp, and radiation shielding.',
      },
    },
    {
      equipped: [
        {
          name: 'Revolver',
          description: 'Range: Close \n\n' +
            'Damage: 1d10 [+] \n\n' +
            'Wound: Gunshot',
          uses: 6,
          maxUses: 6,
          type: EquipmentTypes.GUN,
        },
        {
          name: 'Defibrillator',
          description: 'CLEAR!',
          type: EquipmentTypes.BASIC,
        },
        {
          name: 'First Aid Kit',
          description: 'An assortment of bandages and treatments to help stop bleeding, bandage cuts, and treat other minor injuries.',
          type: EquipmentTypes.BASIC,
        },
        {
          name: 'Flashlight',
          description: 'Handheld or shoulder mounted. Illuminates 10m ahead of the user.',
          type: EquipmentTypes.BASIC,
        },
      ],
      locker: [],
      wearing: {
        name: 'Hazard Suit',
        type: EquipmentTypes.ARMOR,
        armor: 5,
        broken: false,
        description: 'Environmental protection while exploring unknown planets. \n\n' +
          '1 hour O2 supply. \n\n' +
          'Includes: air filter, extreme heat/cold protection, radiation shielding, ' +
          'hydration reclamation (1L of water lasts 4 days), short-range comms, headlamp.',
      },
    },
    {
      equipped: [
        {
          name: 'Foam Gun',
          description: 'Range: Close \n\n' +
            'Damage: 1 \n\n' +
            'Wound: Blunt Force \n\n' +
            'Special: Body Save or become stuck. Strength Check [-] to escape.',
          uses: 2,
          maxUses: 3,
          type: EquipmentTypes.GUN,
        },
        {
          name: 'Sample Collection Kit',
          description: 'Used to research xenoflora and xenofauna in the field. Can take vital signs, DNA samples, and '+
            'collect other data on foreign material. Results may not be instantaneous and may require a lab for complete analysis.',
          type: EquipmentTypes.BASIC,
        },
        {
          name: 'Assorted Tools',
          description: 'Wrenches, spanners, screwdrivers, etc. \n\n' +
            `Range: Adjacent \n\n` +
            'Damage: 1d5 \n\n' +
            'Wound: Blunt Force [+]',
          type: EquipmentTypes.BASIC,
        },
      ],
      locker: [],
      wearing: {
        name: 'Hazard Suit',
        type: EquipmentTypes.ARMOR,
        armor: 5,
        broken: false,
        description: 'Environmental protection while exploring unknown planets. \n\n' +
          '1 hour O2 supply. \n\n' +
          'Includes: air filter, extreme heat/cold protection, radiation shielding, ' +
          'hydration reclamation (1L of water lasts 4 days), short-range comms, headlamp.',
      },
    },
    {
      equipped: [
        {
          name: 'Tranq Pistol',
          description: 'Range: Close \n\n' +
            'Damage: 1 \n\n' +
            'Wound: Blunt Force \n\n' +
            'Special: If Damage dealt: target must Body Save or be unconscious 1d10 rounds.',
          uses: 3,
          maxUses: 6,
          type: EquipmentTypes.GUN,
        },
        {
          name: 'Paracord',
          description: 'General purpose lightweight nylon rope.',
          type: EquipmentTypes.BASIC,
        },
      ],
      locker: [],
      wearing: {
        name: 'Standard Battle Dress',
        type: EquipmentTypes.ARMOR,
        armor: 7,
        broken: false,
        description: 'Lightly-plated armor worn by most marines. \n\n' +
          'Includes: short-range comms.',
      },
    },
    {
      equipped: [
        {
          name: 'Stun Baton',
          description: 'Range: Adjacent \n\n' +
            'Damage: 1d5 \n\n' +
            'Wound: Blunt Force \n\n' +
            'Special: Body Save or stunned 1 round.',
          type: EquipmentTypes.BASIC,
        },
        {
          name: 'Small Pet',
          description: 'You\'ve grown quite fond of each other. \n\n' +
            '1 Wound. 10 Health. \n\n' +
            'Instinct: 43 \n\n' +
            '[+] on Rest Saves \n\n' +
            '1 Stress whenever they take Damage. If killed, Minimum Stress +1 and Panic Check.',
          type: EquipmentTypes.BASIC,
        },
      ],
      locker: [],
      wearing: {
        name: 'Standard Crew Attire',
        type: EquipmentTypes.ARMOR,
        armor: 1,
        broken: false,
        description: 'Coveralls, leather jacket, basic clothing worn by spacecraft crews.',
      },
    },
    {
      equipped: [
        {
          name: 'Scalpel',
          description: 'Range: Adjacent \n\n' +
            'Damage: 1d5 \n\n' +
            'Wound: Bleeding [+]',
          type: EquipmentTypes.BASIC,
        },
        {
          name: 'Bioscanner',
          description: 'Allows the user to scan the immediate area for signs of life. Generally can scan for 100m in all ' +
            'directions without being blocked by most known metals. Can tell the location of signs of life, but not what ' +
            'that life is.',
          type: EquipmentTypes.BASIC,
        },
      ],
      locker: [],
      wearing: {
        name: 'Standard Crew Attire',
        type: EquipmentTypes.ARMOR,
        armor: 1,
        broken: false,
        description: 'Coveralls, leather jacket, basic clothing worn by spacecraft crews.',
      },
    },
    {
      equipped: [
        {
          name: 'Frag Grenade',
          description: 'Range: Long \n\n' +
            'Damage: 1d5-1 Wounds \n\n' +
            'Wound: Fire/Explosives \n\n' +
            'Special: All Close must Body Save to avoid.',
          type: EquipmentTypes.BASIC,
        },
        {
          name: 'Pen Knife',
          description: 'A fine tool, but useless as a weapon - you\'re better off with your fists.',
          type: EquipmentTypes.BASIC,
        },
      ],
      locker: [],
      wearing: {
        name: 'Standard Crew Attire',
        type: EquipmentTypes.ARMOR,
        armor: 1,
        broken: false,
        description: 'Coveralls, leather jacket, basic clothing worn by spacecraft crews.',
      },
    },
    {
      equipped: [
        {
          name: 'Jump-9 Ticket',
          description: 'Destination blank.',
          type: EquipmentTypes.BASIC,
        },
      ],
      locker: [],
      wearing: {
        name: 'Manufacturer Supplied Attire',
        type: EquipmentTypes.ARMOR,
        armor: 1,
        broken: false,
        description: 'Straight off the line.',
      },
    },
    {
      equipped: [
        {
          name: 'VIP Corporate Key Card',
          description: 'Do you think they\'ve disabled it yet?',
          type: EquipmentTypes.BASIC,
        },
      ],
      locker: [],
      wearing: {
        name: 'Corporate Attire',
        type: EquipmentTypes.ARMOR,
        armor: 1,
        broken: false,
        description: 'You are certainly not dressed for manual labor.',
      },
    },
  ],
  [Classes.SCIENTIST]: [
    {
      equipped: [
        {
          name: 'Tranq Pistol',
          description: 'Range: Close \n\n' +
            'Damage: 1 \n\n' +
            'Wound: Blunt Force \n\n' +
            'Special: If Damage dealt: target must Body Save or be unconscious 1d10 rounds.',
          uses: 3,
          maxUses: 6,
          type: EquipmentTypes.GUN,
        },
        {
          name: 'Bioscanner',
          description: 'Allows the user to scan the immediate area for signs of life. Generally can scan for 100m in all ' +
            'directions without being blocked by most known metals. Can tell the location of signs of life, but not what ' +
            'that life is.',
          type: EquipmentTypes.BASIC,
        },
        {
          name: 'Sample Collection Kit',
          description: 'Used to research xenoflora and xenofauna in the field. Can take vital signs, DNA samples, and '+
            'collect other data on foreign material. Results may not be instantaneous and may require a lab for complete analysis.',
          type: EquipmentTypes.BASIC,
        },
      ],
      locker: [],
      wearing:     {
        name: 'Hazard Suit',
        type: EquipmentTypes.ARMOR,
        armor: 5,
        broken: false,
        description: 'Environmental protection while exploring unknown planets. \n\n' +
          '1 hour O2 supply. \n\n' +
          'Includes: air filter, extreme heat/cold protection, radiation shielding, ' +
          'hydration reclamation (1L of water lasts 4 days), short-range comms, headlamp.',
      },
    },
    {
      equipped: [
        {
          name: 'Flamethrower',
          description: 'Range: Close \n\n' +
            'Damage: 1 Wound \n\n' +
            'Wound: Fire/Explosives [+] \n\n' +
            'Special: If liquid fuel tank is destroyed, 1 Wound to all Close',
          uses: 1,
          maxUses: 4,
          type: EquipmentTypes.GUN,
        },
        {
          name: 'Pain Pills',
          description: 'When ingested, immediately restores 1d10 health and lowers Stress by 1. There is a danger of '+
            'addiction and/or overdose if used frequently.',
          type: EquipmentTypes.USES,
          uses: 5,
          maxUses: 5,
        },
        {
          name: 'Electronic Tool Set',
          description: 'A full set of tools for doing detailed repair or construction work on electronics.',
          type: EquipmentTypes.BASIC,
        },
      ],
      locker: [],
      wearing:     {
        name: 'Hazard Suit',
        type: EquipmentTypes.ARMOR,
        armor: 5,
        broken: false,
        description: 'Environmental protection while exploring unknown planets. \n\n' +
          '1 hour O2 supply. \n\n' +
          'Includes: air filter, extreme heat/cold protection, radiation shielding, ' +
          'hydration reclamation (1L of water lasts 4 days), short-range comms, headlamp.',
      },
    },
    {
      equipped: [
        {
          name: 'Rigging Gun',
          description: 'Range: Close \n\n' +
            'Damage: 1d10 + 2d10 when removed \n\n' +
            'Wound: Bleeding [+] \n\n' +
            'Special: 100m microfilament. Body Save or become entangled.',
          uses: 1,
          maxUses: 1,
          type: EquipmentTypes.GUN,
        },
        {
          name: 'Sample Collection Kit',
          description: 'Used to research xenoflora and xenofauna in the field. Can take vital signs, DNA samples, and '+
            'collect other data on foreign material. Results may not be instantaneous and may require a lab for complete analysis.',
          type: EquipmentTypes.BASIC,
        },
        {
          name: 'Flashlight',
          description: 'Handheld or shoulder mounted. Illuminates 10m ahead of the user.',
          type: EquipmentTypes.BASIC,
        },
        {
          name: 'Lab Rat',
          description: 'Easily as smart as any of your crewmates, if not smarter. \n\n' +
            '1 Wound. 10 Health. \n\n' +
            'Instinct: 56 \n\n' +
            '[+] on Rest Saves \n\n' +
            '1 Stress whenever they take Damage. If killed, Minimum Stress +1 and Panic Check.',
          type: EquipmentTypes.BASIC,
        },
      ],
      locker: [],
      wearing: {
        name: 'Vaccsuit',
        type: EquipmentTypes.ARMOR,
        armor: 3,
        broken: false,
        description: 'Worn while operating in outer space. \n\n' +
          'Disadvantage on speed checks. \n\n' +
          '12 hours O2 supply. \n\n' +
          'Decompression within 1d5 rounds when punctured. \n\n' +
          'Includes: short-range comms, headlamp, and radiation shielding.',
      },
    },
    {
      equipped: [
        {
          name: 'Foam Gun',
          description: 'Range: Close \n\n' +
            'Damage: 1 \n\n' +
            'Wound: Blunt Force \n\n' +
            'Special: Body Save or become stuck. Strength Check [-] to escape.',
          uses: 2,
          maxUses: 3,
          type: EquipmentTypes.GUN,
        },
        {
          name: 'Foldable Stretcher',
          description: 'Portable stretcher that can fit within a rucksack. Allows the user to safely strap down the patient ' +
            'and carry them to a locatoin where their wounds can be better treated. Unfolds to roughly 2m.',
          type: EquipmentTypes.BASIC,
        },
        {
          name: 'First Aid Kit',
          description: 'An assortment of bandages and treatments to help stop bleeding, bandage cuts, and treat other minor injuries.',
          type: EquipmentTypes.BASIC,
        },
        {
          name: 'Radiation Pills',
          description: 'Take 1d5 Damage and reduce your Radiation Level by 1 for 2d10 minutes.',
          type: EquipmentTypes.USES,
          uses: 5,
          maxUses: 5,
        },
      ],
      locker: [],
      wearing: {
        name: 'Vaccsuit',
        type: EquipmentTypes.ARMOR,
        armor: 3,
        broken: false,
        description: 'Worn while operating in outer space. \n\n' +
          'Disadvantage on speed checks. \n\n' +
          '12 hours O2 supply. \n\n' +
          'Decompression within 1d5 rounds when punctured. \n\n' +
          'Includes: short-range comms, headlamp, and radiation shielding.',
      },
    },
    {
      equipped: [
        {
          name: 'Assorted Tools',
          description: 'Wrenches, spanners, screwdrivers, etc. \n\n' +
            `Range: Adjacent \n\n` +
            'Damage: 1d5 \n\n' +
            'Wound: Blunt Force [+]',
          type: EquipmentTypes.BASIC,
        },
        {
          name: 'Medscanner',
          description: 'Allows the user to scan a living or dead body to analyze it for disease or abnormalities, without ' +
            'having to do a biopsy or autopsy. Results are often non-instantaneous and may require a lab for analysis.',
          type: EquipmentTypes.BASIC,
        },
        {
          name: 'Vaccine',
          description: 'Against...?',
          type: EquipmentTypes.USES,
          uses: 1,
          maxUses: 4,
        }
      ],
      locker: [],
      wearing: {
        name: 'Lab Coat',
        type: EquipmentTypes.ARMOR,
        armor: 1,
        broken: false,
        description: 'Standard battle dress from the front lines of the War on Ignorance.',
      },
    },
    {
      equipped: [
        {
          name: 'Cybernetic Diagnostic Scanner',
          description: 'Allows the user to scan androids and other cybernetic organisms in order to diagnose any physical ' +
            'or mental issues they may be having. Often distrusted by androids.',
          type: EquipmentTypes.BASIC,
        },
        {
          name: 'Portable Computer Terminal',
          description: 'Flat computer monitor, keyboard, and interface which allows the user to hack into computers and ' +
            'networks, as well as perform standard computer tasks.',
          type: EquipmentTypes.BASIC,
        },
      ],
      locker: [],
      wearing: {
        name: 'Lab Coat',
        type: EquipmentTypes.ARMOR,
        armor: 1,
        broken: false,
        description: 'Standard battle dress from the front lines of the War on Ignorance.',
      },
    },
    {
      equipped: [
        {
          name: 'Scalpel',
          description: 'Range: Adjacent \n\n' +
            'Damage: 1d5 \n\n' +
            'Wound: Bleeding [+]',
          type: EquipmentTypes.BASIC,
        },
        {
          name: 'Automed',
          description: 'Nonotech pills that assist your body in repairing Damage by granting Advantage to Body Saves meant ' +
            'to repel disease and poison, as well as attempts to heal from rest.',
          type: EquipmentTypes.USES,
          uses: 5,
          maxUses: 5,
        },
        {
          name: 'Automed',
          description: 'Nonotech pills that assist your body in repairing Damage by granting Advantage to Body Saves meant ' +
            'to repel disease and poison, as well as attempts to heal from rest.',
          type: EquipmentTypes.USES,
          uses: 1,
          maxUses: 5,
        },
        {
          name: 'Oxygen Tank',
          description: 'When attached to a vaccsuit, provides up to 12 hours of oxygen under normal circumstances, 4 hours ' +
            'under stressful circumstances. \n\n Explosive.',
          type: EquipmentTypes.BASIC,
        },
      ],
      locker: [],
      wearing: {
        name: 'Scrubs',
        type: EquipmentTypes.ARMOR,
        armor: 1,
        broken: false,
        description: '"Relax, that isn\'t my blood."',
      },
    },
    {
      equipped: [
        {
          name: 'Vial of Acid',
          description: 'Acid in a sealed vial.',
          type: EquipmentTypes.BASIC,
        },
        {
          name: 'Mylar Blanket',
          description: 'Lightweight blanket made of heat-reflective material. Often used for thermal control of patients ' +
            'suffering from extreme cold or other trauma.',
          type: EquipmentTypes.BASIC,
        },
        {
          name: 'First Aid Kit',
          description: 'An assortment of bandages and treatments to help stop bleeding, bandage cuts, and treat other minor injuries.',
          type: EquipmentTypes.BASIC,
        },
      ],
      locker: [],
      wearing: {
        name: 'Scrubs',
        type: EquipmentTypes.ARMOR,
        armor: 1,
        broken: false,
        description: '"Relax, that isn\'t my blood."',
      },
    },
    {
      equipped: [
        {
          name: 'Utility Knife',
          description: 'Range: Adjacent \n\n' +
            'Damage: 1d5 \n\n' +
            'Wound: Bleeding [+]',
          type: EquipmentTypes.BASIC,
        },
        {
          name: 'Cybernetic Diagnostic Scanner',
          description: 'Allows the user to scan androids and other cybernetic organisms in order to diagnose any physical ' +
            'or mental issues they may be having. Often distrusted by androids.',
          type: EquipmentTypes.BASIC,
        },
        {
          name: 'Duct Tape',
          description: 'Works on more than just ducts.',
          type: EquipmentTypes.BASIC,
        },
      ],
      locker: [],
      wearing: {
        name: 'Standard Crew Attire',
        type: EquipmentTypes.ARMOR,
        armor: 1,
        broken: false,
        description: 'Coveralls, leather jacket, basic clothing worn by spacecraft crews.',
      },
    },
    {
      equipped: [
        {
          name: 'Briefcase',
          description: 'It locks to protect your secrets.',
          type: EquipmentTypes.BASIC,
        },
        {
          name: 'Prescription Pad',
          description: 'Don\'t abuse it.',
          type: EquipmentTypes.BASIC,
        },
        {
          name: 'Fountain Pen...',
          description: '...that\'s actually a poison injector.',
          type: EquipmentTypes.BASIC,
        },
      ],
      locker: [],
      wearing: {
        name: 'Civilian Clothes',
        type: EquipmentTypes.ARMOR,
        armor: 1,
        broken: false,
        description: 'Not very professional.',
      },
    },
  ],
  [Classes.TEAMSTER]: [
    {
      equipped: [
        {
          name: 'Laser Cutter',
          description: 'Range: Long \n\n' +
            'Damage: 1d100 \n\n' +
            'Wound: Bleeding [+] or Gore [+] \n\n' +
            'Special: Two-handed. Heavy. Must recharge between shots. Reload: 1hr (power), 6hr (solar).',
          uses: 2,
          maxUses: 6,
          type: EquipmentTypes.GUN,
        },
        {
          name: 'Patch Kit',
          description: 'Repairs punctured and torn vaccsuits, restoring their space readiness. Patched Vaccsuits have an ' +
            'AP of 1.',
          type: EquipmentTypes.USES,
          uses: 3,
          maxUses: 3,
        },
        {
          name: 'Tool Belt',
          description: 'Wrenches, spanners, screwdrivers, etc. \n\n' +
            `Range: Adjacent \n\n` +
            'Damage: 1d5 \n\n' +
            'Wound: Blunt Force [+]',
          type: EquipmentTypes.BASIC,
        },
      ],
      locker: [],
      wearing: {
        name: 'Vaccsuit',
        type: EquipmentTypes.ARMOR,
        armor: 3,
        broken: false,
        description: 'Worn while operating in outer space. \n\n' +
          'Disadvantage on speed checks. \n\n' +
          '12 hours O2 supply. \n\n' +
          'Decompression within 1d5 rounds when punctured. \n\n' +
          'Includes: short-range comms, headlamp, and radiation shielding.',
      },
    },
    {
      equipped: [
        {
          name: 'Revolver',
          description: 'Range: Close \n\n' +
            'Damage: 1d10 [+] \n\n' +
            'Wound: Gunshot',
          uses: 6,
          maxUses: 6,
          type: EquipmentTypes.GUN,
        },
        {
          name: 'Crowbar',
          description: 'Grants advantage on Strength Checks to open jammed airlocks, lift heavy objects, etc. \n\n' +
            'Range: Adjacent \n\n' +
            'Damage: 1d5 \n\n' +
            'Wound: Blunt Force [+]',
          type: EquipmentTypes.BASIC,
        },
        {
          name: 'Flashlight',
          description: 'Handheld or shoulder mounted. Illuminates 10m ahead of the user.',
          type: EquipmentTypes.BASIC,
        },
      ],
      locker: [],
      wearing: {
        name: 'Vaccsuit',
        type: EquipmentTypes.ARMOR,
        armor: 3,
        broken: false,
        description: 'Worn while operating in outer space. \n\n' +
          'Disadvantage on speed checks. \n\n' +
          '12 hours O2 supply. \n\n' +
          'Decompression within 1d5 rounds when punctured. \n\n' +
          'Includes: short-range comms, headlamp, and radiation shielding.',
      },
    },
    {
      equipped: [
        {
          name: 'Rigging Gun',
          description: 'Range: Close \n\n' +
            'Damage: 1d10 + 2d10 when removed \n\n' +
            'Wound: Bleeding [+] \n\n' +
            'Special: 100m microfilament. Body Save or become entangled.',
          uses: 1,
          maxUses: 1,
          type: EquipmentTypes.GUN,
        },
        {
          name: 'Shovel',
          description: 'Made for digging. \n\n' +
            'Range: Adjacent \n\n' +
            'Damage: 1d5 \n\n' +
            'Wound: Blunt Force',
          type: EquipmentTypes.BASIC,
        },
        {
          name: 'Salvage Drone',
          description: 'Remote controlled drone. Requires two hands to operate receiver. Can fly up to 10m high, to anywhere Long ' +
            'Range from the operator. Battery operated. Can run for 2 hours. Can record and transmit footage to receiver. ' +
            'Can be equipped with a laser cutter. Can carry up to 225kg.',
          type: EquipmentTypes.BASIC,
        },
      ],
      locker: [],
      wearing: {
        name: 'Vaccsuit',
        type: EquipmentTypes.ARMOR,
        armor: 3,
        broken: false,
        description: 'Worn while operating in outer space. \n\n' +
          'Disadvantage on speed checks. \n\n' +
          '12 hours O2 supply. \n\n' +
          'Decompression within 1d5 rounds when punctured. \n\n' +
          'Includes: short-range comms, headlamp, and radiation shielding.',
      },
    },
    {
      equipped: [
        {
          name: 'Vibechete',
          description: 'Range: Adjacent \n\n' +
            'Damage: 3d10 \n\n' +
            'Wound: Bleeding + Gore \n\n' +
            'Special: When dealing a Wound, roll on BOTH the Bleeding AND Gore columns.',
          type: EquipmentTypes.BASIC,
        },
        {
          name: 'Assorted Tools',
          description: 'Wrenches, spanners, screwdrivers, etc. \n\n' +
            `Range: Adjacent \n\n` +
            'Damage: 1d5 \n\n' +
            'Wound: Blunt Force [+]',
          type: EquipmentTypes.BASIC,
        },
        {
          name: 'MoHab Unit',
          description: 'Tent, canteen, stove, rucksack, compass, and sleeping bag.',
          type: EquipmentTypes.BASIC,
        },
        {
          name: 'Water Filtration Device',
          description: 'Can pump 50 liters of filtered water per hour from even teh most brackish swamps.',
          type: EquipmentTypes.BASIC,
        }
      ],
      locker: [],
      wearing: {
        name: 'Hazard Suit',
        type: EquipmentTypes.ARMOR,
        armor: 5,
        broken: false,
        description: 'Environmental protection while exploring unknown planets. \n\n' +
          '1 hour O2 supply. \n\n' +
          'Includes: air filter, extreme heat/cold protection, radiation shielding, ' +
          'hydration reclamation (1L of water lasts 4 days), short-range comms, headlamp.',
      },
    },
    {
      equipped: [
        {
          name: 'Explosives and Detonator',
          description: 'Explosive charge powerful enough to blow open an airlock. All Close organisms must make a Body Save or ' +
            'take a Wound (Explosive). Detonator works at Long Range, but can be blocked by a radio jammer.',
          type: EquipmentTypes.BASIC,
        },
        {
          name: 'Cigarettes',
          description: 'But what did you do with your lighter?',
          type: EquipmentTypes.BASIC,
        },
      ],
      locker: [],
      wearing: {
        name: 'Heavy Duty Work Clothes',
        type: EquipmentTypes.ARMOR,
        armor: 2,
        broken: false,
        description: 'For dirtier than average jobs.',
      },
    },
    {
      equipped: [
        {
          name: 'Drill',
          description: 'Makes holes. \n\n' +
            `Range: Adjacent \n\n` +
            'Damage: 1d10 \n\n' +
            'Wound: Bleeding [-]',
          type: EquipmentTypes.BASIC,
        },
        {
          name: 'Paracord',
          description: 'General purpose lightweight nylon rope.',
          type: EquipmentTypes.BASIC,
        },
        {
          name: 'Salvage Drone',
          description: 'Remote controlled drone. Requires two hands to operate receiver. Can fly up to 10m high, to anywhere Long ' +
            'Range from the operator. Battery operated. Can run for 2 hours. Can record and transmit footage to receiver. ' +
            'Can be equipped with a laser cutter. Can carry up to 225kg.',
          type: EquipmentTypes.BASIC,
        },
      ],
      locker: [],
      wearing:  {
        name: 'Heavy Duty Work Clothes',
        type: EquipmentTypes.ARMOR,
        armor: 2,
        broken: false,
        description: 'For dirtier than average jobs.',
      },
    },
    {
      equipped: [
        {
          name: 'Combat Shotgun',
          description: 'Range: Close \n\n' +
            'Damage: 1 Wound \n\n' +
            'Wound: Gunshot \n\n' +
            'Special: 1d10 DMG at Long Range',
          type: EquipmentTypes.GUN,
          uses: 4,
          maxUses: 4,
        },
        {
          name: 'Extension Cord',
          description: '20 meters of power extension.',
          type: EquipmentTypes.BASIC,
        },
        {
          name: 'Cat',
          description: 'They do what they wants. \n\n' +
            '1 Wound. 10 Health. \n\n' +
            'Instinct: 60 \n\n' +
            '[+] on Rest Saves \n\n' +
            '1 Stress whenever they take Damage. If killed, Minimum Stress +1 and Panic Check.',
          type: EquipmentTypes.BASIC,
        },
      ],
      locker: [],
      wearing: {
        name: 'Standard Crew Attire',
        type: EquipmentTypes.ARMOR,
        armor: 1,
        broken: false,
        description: 'Coveralls, leather jacket, basic clothing worn by spacecraft crews.',
      },
    },
    {
      equipped: [
        {
          name: 'Nail Gun',
          description: 'Range: Close \n\n' +
            'Damage: 1d5 \n\n' +
            'Wound: Bleeding',
          uses: 32,
          maxUses: 32,
          type: EquipmentTypes.GUN,
        },
        {
          name: 'Head Lamp',
          description: 'Hands-free. Illuminates 10m ahead of the user.',
          type: EquipmentTypes.BASIC,
        },
        {
          name: 'Tool Belt',
          description: 'Wrenches, spanners, screwdrivers, etc. \n\n' +
            `Range: Adjacent \n\n` +
            'Damage: 1d5 \n\n' +
            'Wound: Blunt Force [+]',
          type: EquipmentTypes.BASIC,
        },
        {
          name: 'Lunch Box',
          description: 'Branded with a children\'s character hologram.',
          type: EquipmentTypes.BASIC,
        },
      ],
      locker: [],
      wearing: {
        name: 'Standard Crew Attire',
        type: EquipmentTypes.ARMOR,
        armor: 1,
        broken: false,
        description: 'Coveralls, leather jacket, basic clothing worn by spacecraft crews.',
      },
    },
    {
      equipped: [
        {
          name: 'Flare Gun',
          description: 'Range: Long \n\n' +
            'Damage: 1d5 \n\n' +
            'Wound: Fire/Explosives [-] \n\n' +
            'Special: High intensity flare visible day and night from Long Range',
          uses: 1,
          maxUses: 2,
          type: EquipmentTypes.GUN,
        },
        {
          name: 'Water Filtration Device',
          description: 'Can pump 50 liters of filtered water per hour from even teh most brackish swamps.',
          type: EquipmentTypes.BASIC,
        },
        {
          name: 'Personal Locator',
          description: 'Allows crewmembers at a control center or on the bridge of a ship to track the location of the wearer.',
          type: EquipmentTypes.BASIC,
        },
        {
          name: 'Bioscanner',
          description: 'Allows the user to scan the immediate area for signs of life. Generally can scan for 100m in all ' +
            'directions without being blocked by most known metals. Can tell the location of signs of life, but not what ' +
            'that life is.',
          type: EquipmentTypes.BASIC,
        },
      ],
      locker: [],
      wearing: {
        name: 'Standard Crew Attire',
        type: EquipmentTypes.ARMOR,
        armor: 1,
        broken: false,
        description: 'Coveralls, leather jacket, basic clothing worn by spacecraft crews.',
      },
    },
    {
      equipped: [
        {
          name: 'Crowbar',
          description: 'Grants advantage on Strength Checks to open jammed airlocks, lift heavy objects, etc. \n\n' +
            'Range: Adjacent \n\n' +
            'Damage: 1d5 \n\n' +
            'Wound: Blunt Force [+]',
          type: EquipmentTypes.BASIC,
        },
        {
          name: 'Pain Pills',
          description: 'When ingested, immediately restores 1d10 health and lowers Stress by 1. There is a danger of '+
            'addiction and/or overdose if used frequently.',
          type: EquipmentTypes.USES,
          uses: 5,
          maxUses: 5,
        },
        {
          name: 'Beer',
          description: 'Liquid gold.',
          type: EquipmentTypes.USES,
          uses: 6,
          maxUses: 6,
        },
      ],
      locker: [],
      wearing: {
        name: 'Lounge Wear',
        type: EquipmentTypes.ARMOR,
        armor: 1,
        broken: false,
        description: 'Time to get comfy.',
      },
    },
  ],
}

export default loadouts
